.new-text-style {
	font-family: 'Inter-SemiBold' !important;
	font-size: 25px !important;
	color: var(--white);
}

.new-text-style-2 {
	font-family: 'Inter-SemiBold' !important;
	font-size: 17px !important;
	color: var(--white) !important;
}

.new-text-style-3 {
	font-family: 'Inter-Medium' !important;
	font-size: 13px !important;
	color: var(--black) !important;
}

.new-text-style-4 {
	font-family: 'Inter-Bold' !important;
	font-size: 13px !important;
	color: var(--black) !important;
}

.new-text-style-5 {
	font-family: 'Inter-Bold' !important;
	font-size: 13px !important;
	color: var(--white) !important;
}

.new-text-style-6 {
	font-family: 'Inter-Regular' !important;
	font-size: 13px !important;
	color: var(--black) !important;
}

.new-text-style-6-red {
	font-family: 'Inter-Regular' !important;
	font-size: 13px !important;
	color: var(--grapefruit) !important;
}

.new-text-style-6-orange {
	font-family: 'Inter-Regular' !important;
	font-size: 13px !important;
	color: var(--yellowish-orange) !important;
}

.new-text-style-7 {
	font-family: 'Inter-SemiBold' !important;
	font-size: 13px !important;
	color: var(--black) !important;
}

.new-text-style-8 {
	font-family: 'Inter-Medium' !important;
	font-size: 14px !important;
	color: var(--black) !important;
}

.new-text-style-9 {
	font-family: 'Inter-Bold' !important;
	font-size: 14px !important;
	color: var(--white) !important;
}

.new-text-style-10 {
	font-family: 'Inter-Italic' !important;
	font-size: 14px !important;
	color: var(--warm-grey) !important;
}

.new-text-style-11 {
	font-family: 'Inter-Bold' !important;
	font-size: 14px !important;
	color: var(--azure) !important;
}

.new-text-style-12 {
	font-family: 'Inter-Medium' !important;
	font-size: 14px !important;
	color: var(--twilight-blue) !important;
}

.new-text-style-13 {
	font-family: 'Inter-SemiBold' !important;
	font-size: 15px !important;
	color: var(--black) !important;
}

.new-text-style-14 {
	font-family: 'Inter-Medium' !important;
	font-size: 17px !important;
	color: var(--sickly-yellow) !important;
}

.new-text-style-15 {
	font-family: 'Inter-Regular' !important;
	font-size: 13px !important;
	color: var(--warm-grey) !important;
}

.new-text-style-16 {
	font-family: 'Inter-Bold' !important;
	font-size: 16px !important;
	color: var(--black) !important;
}

.new-text-style-17 {
	font-family: 'Inter-Regular' !important;
	font-size: 9px !important;
	color: var(--black) !important;
}

.new-text-style-18 {
	font-family: 'Inter-Medium' !important;
	font-size: 11px !important;
	color: var(--black) !important;
}

.new-text-style-19 {
	font-family: 'Inter-Regular' !important;
	font-size: 11px !important;
	color: var(--black) !important;
}

.new-text-style-20 {
	font-family: 'Inter-Regular' !important;
	font-size: 11px !important;
	color: var(--warm-grey) !important;
}

.new-text-style-21 {
	font-family: 'Inter-Bold' !important;
	font-size: 15px !important;
	color: var(--black) !important;
}

.new-text-style-22 {
	font-family: 'Inter-Medium' !important;
	font-size: 15px !important;
	color: var(--black) !important;
}

.new-text-style-23 {
	font-family: 'Inter-Medium' !important;
	font-size: 15px !important;
	color: var(--azure) !important;
}

.new-text-style-24 {
	font-family: Inter !important;
	font-size: 15px !important;
	color: var(--azure) !important;
}

.new-text-style-25 {
	font-family: 'Inter-Regular' !important;
	font-size: 15px !important;
	color: var(--black) !important;
}

.new-text-style-26 {
	font-family: 'Inter-SemiBold' !important;
	font-size: 13px !important;
	color: var(--azure) !important;
}

.new-text-style-27 {
	font-family: 'Inter-Medium' !important;
	font-size: 13px !important;
	color: var(--azure) !important;
}

.new-text-style-28 {
	font-family: 'Inter-Regular' !important;
	font-size: 14px !important;
	color: var(--black) !important;
}

.new-text-style-29 {
	font-family: 'Inter-SemiBold' !important;
	font-size: 20px !important;
	color: var(--black) !important;
}

.new-text-style-30 {
	font-family: 'Inter-SemiBold' !important;
	font-size: 14px !important;
	color: var(--black) !important;
}

.new-text-style-31 {
	font-family: 'Inter-SemiBold' !important;
	font-size: 15px !important;
	color: var(--white) !important;
}

.new-text-style-32 {
	font-family: 'Inter-SemiBold' !important;
	font-size: 17px !important;
	color: var(--black) !important;
}

.new-text-style-33 {
	font-family: 'Inter-SemiBold' !important;
	font-size: 25px !important;
	color: var(--black) !important;
}

.new-text-style-34 {
	font-family: 'Inter-Bold' !important;
	font-size: 13px !important;
	color: var(--twilight-blue) !important;
}
