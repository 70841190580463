// Backgrounds and colors

.background-header {
	background-color: linear-gradient(to right, var(--azure) 0%, var(--twilight-blue));
	--background: linear-gradient(to right, var(--azure) 0%, var(--twilight-blue));
}

.background-azure {
	background-color: var(--azure) !important;
	--background: var(--azure) !important;
}

.background-azure-5 {
	background-color: var(--azure-5) !important;
	--background: var(--azure-5) !important;
}

.background-azure-10 {
	background-color: var(--azure-10) !important;
	--background: var(--azure-10) !important;
}

.background-azure-light {
	background-color: var(--azure-light) !important;
	--background: var(--azure-light) !important;
}

.background-sickly-yellow {
	background-color: var(--sickly-yellow) !important;
	--background: var(--sickly-yellow) !important;
}

.background-sand {
	background-color: var(--sand) !important;
	--background: var(--sand) !important;
}

.background-light-grey-green {
	background-color: var(--light-grey-green) !important;
	--background: var(--light-grey-green) !important;
}

.background-duck-egg-blue {
	background-color: var(--duck-egg-blue) !important;
	--background: var(--duck-egg-blue) !important;
}

.background-light-seafoam-blue {
	background-color: var(--seafoam-blue) !important;
	--background: var(--seafoam-blue) !important;
}

.background-light-warm-grey {
	background-color: var(--warm-grey) !important;
	--background: var(--warm-grey) !important;
}

.background-white {
	background-color: var(--white) !important;
	--background: var(--white) !important;
}

.background-very-light-pink {
	background-color: var(--very-light-pink) !important;
	--background: var(--very-light-pink) !important;
}

.background-very-light-pink-two {
	background-color: var(--very-light-pink-two) !important;
	--background: var(--very-light-pink-two) !important;
}

.background-very-light-pink-three {
	background-color: var(--very-light-pink-three) !important;
	--background: var(--very-light-pink-three) !important;
}

.background-transparent {
	background-color: transparent !important;
	--background: transparent !important;
}

.background-fafafa {
	background-color: #fafafa;
	--background: #fafafa;
}

.background-azure-20 {
	background-color: rgba(0, 173, 239, 0.2);
	--background: rgba(0, 173, 239, 0.2);
}

.background-jordy-blue {
	background-color: var(--jordy-blue) !important;
	--background: var(--jordy-blue) !important;
}

.background-pale-grey {
	background-color: var(--pale-grey) !important;
	--background: var(--pale-grey) !important;
}

.background-creme {
	background-color: var(--creme) !important;
	--background: var(--creme) !important;
}

.background-creme-30 {
	background-color: var(--creme-30) !important;
	--background: var(--creme-30) !important;
}

.background-light-khaki {
	background-color: var(--light-khaki) !important;
	--background: var(--light-khaki) !important;
}

.background-light-green {
	background-color: var(--light-green) !important;
	--background: var(--light-green) !important;
}

.background-powder-blue {
	background-color: var(--powder-blue) !important;
	--background: var(--powder-blue) !important;
}

.background-powder-blue-two {
	background-color: var(--powder-blue-two) !important;
	--background: var(--powder-blue-two) !important;
}

.background-pale-blue {
	background-color: var(--pale-blue) !important;
	--background: var(--pale-blue) !important;
}

.background-vanilla {
	background-color: var(--vanilla) !important;
	--background: var(--vanilla) !important;
}

.background-light-pink {
	background-color: var(--light-pink) !important;
	--background: var(--light-pink) !important;
}

.background-buff {
	background-color: var(--buff) !important;
	--background: var(--buff) !important;
}

.background-pale-salmon {
	background-color: var(--pale-salmon) !important;
	--background: var(--pale-salmon) !important;
}

.background-baby-blue {
	background-color: var(--baby-blue) !important;
	--background: var(--baby-blue) !important;
}

.background-light-teal {
	background-color: var(--light-teal) !important;
	--background: var(--light-teal) !important;
}

.background-yellowish-orange {
	background-color: var(--yellowish-orange) !important;
	--background: var(--yellowish-orange) !important;
}

.background-sunshine-yellow {
	background-color: var(--sunshine-yellow) !important;
	--background: var(--sunshine-yellow) !important;
}

.background-white-smoke {
	background-color: var(--white-smoke) !important;
}

.background-weekend-planning {
	background-color: rgba(215, 215, 215, 0.3) !important;
}

.background-ferie-planning {
	background-color: #8c8c8c !important;
}

.color-white {
	color: var(--white) !important;
}

.background-stripes-0c4d98 {
	background: repeating-linear-gradient(45deg, #0c4d984d, #0c4d984d 3px, #ffffff 3px, #ffffff 6px) !important;
}

.background-stripes-259972 {
	background: repeating-linear-gradient(45deg, #2599724d, #2599724d 3px, #ffffff 3px, #ffffff 6px) !important;
}

// Borders

.no-border {
	border: none !important;
}

.border-dashed {
	border-style: dashed !important;
}

.border-bottom-none {
	border-bottom: none !important;
}

.border-bottom-sickly-yellow-3px {
	border-bottom: 3px solid var(--sickly-yellow) !important;
}

.border-grapefruit-2px {
	border: solid 2px var(--grapefruit);
}

.border-bottom-transparent-3px {
	border-bottom: 3px solid transparent !important;
}

.border-very-light-pink {
	border: solid 1px var(--very-light-pink) !important;
}

.border-bottom-very-light-pink {
	border-bottom: solid 1px var(--very-light-pink) !important;
}

.border-bottom-azure {
	border-bottom: 1px solid var(--azure);
}

.border-bottom-azure-2px {
	border-bottom: 2px solid var(--azure);
}

.border-bottom-twilight-blue {
	border-bottom: solid 1px var(--twilight-blue) !important;
}

.border-bottom-twilight-blue-2px {
	border-bottom: solid 2px var(--twilight-blue) !important;
}

.border-bottom-twilight-blue-3px {
	border-bottom: solid 3px var(--twilight-blue) !important;
}

.border-bottom-twilight-blue-30 {
	border-bottom: solid 1px var(--twilight-blue-30) !important;
}

.border-bottom-twilight-blue-30-2px {
	border-bottom: solid 2px var(--twilight-blue-30) !important;
}

.border-top-twilight-blue-30-2px {
	border-top: solid 2px var(--twilight-blue-30) !important;
}

.border-azure {
	border: 1px solid var(--azure);
}

.border-left-azure {
	border-left: 1px solid var(--azure);
}

.border-bottom-azure-8px {
	border-bottom: solid 8px var(--azure) !important;
}

.border-bottom-white-grey-2 {
	border-bottom: solid 1px var(--white-grey-2) !important;
}

.border-right-white-grey {
	border-right: 1px solid var(--white-grey);
}

.border-bottom-white-grey {
	border-bottom: 1px solid var(--white-grey);
}

.border-warm-grey {
	border: 1px solid var(--warm-grey);
}

.border-right-warm-grey-2px {
	border-right: 2px solid var(--warm-grey);
}

.border-bottom-warm-grey {
	border-bottom: 1px solid var(--warm-grey);
}

.border-bottom-warm-grey-2px {
	border-bottom: 2px solid var(--warm-grey);
}

.border-greyish-brown {
	border: solid 1px var(--greyish-brown);
}

.border-black {
	border: solid 1px var(--greyish-brown);
}

.border-bottom-black {
	border-bottom: solid 1px var(--greyish-brown);
}

.border-right-white {
	border-right: 1px solid var(--white);
}

.border-bottom-none {
	border-bottom: none !important;
}

.border-radius {
	border-radius: 4px;
}

.border-radius-6 {
	border-radius: 6px !important;
}

.border-radius-8 {
	border-radius: 8px !important;
}

.border-top-left-radius-8 {
	border-top-left-radius: 8px !important;
}

.border-top-right-radius-8 {
	border-top-right-radius: 8px !important;
}

.border-radius-100 {
	border-radius: 100% !important;
}

.box-shadow-very-light-pink {
	box-shadow: 0 2px 4px 0 var(--very-light-pink) !important;
}

.box-shadow-greyish-10 {
	box-shadow: 0 0 20px 0 var(--greyish-10) !important;
}

.box-shadow-black-10 {
	box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1) !important;
}

// Segments

.segment-mystemi {

	--min-height: 0 !important;

	&::-webkit-scrollbar {
		width: 0;
		height: 0;
		display: none;
	}
}

.segment-button-mystemi {
	text-transform: none !important;
	letter-spacing: normal !important;
	max-width: fit-content !important;
	--background: transparent !important;
	--background-checked: transparent !important;
	--background-focused: transparent !important;
	--background-hover: transparent !important;
	--background-hover-opacity: transparent !important;
	--indicator-box-shadow: none !important;
	--border-width: 0 !important;
	--color-checked: transparent !important;
	--color-focused: transparent !important;
	--color-hover: transparent !important;
	--indicator-color: transparent !important;
	--padding-end: 0 !important;
	--padding-start: 0 !important;
	--ripple-color: transparent !important;
}

// Margins Paddings

.stemi-desktop {

	.margin-small {
		margin: 10px !important;
	}

	.margin-bottom-small {
		margin: 0 0 10px !important;
	}

	.margin-bottom-small-right-medium {
		margin: 0 15px 10px 0 !important;
	}

	.margin-bottom-small-right-large {
		margin: 0 30px 10px 0 !important;
	}

	.margin-bottom-left-small {
		margin: 0 0 10px 15px !important;
	}

	.margin-bottom-medium {
		margin: 0 0 15px !important;
	}

	.margin-bottom-top-medium {
		margin: 15px 0 !important;
	}

	.margin-bottom-medium-right-large {
		margin: 0 30px 15px 0 !important;
	}

	.margin-bottom-left-medium {
		margin: 0 0 15px 15px !important;
	}

	.margin-bottom-large {
		margin: 0 0 20px !important;
	}

	.margin-bottom-very-right-very-large {
		margin: 0 30px 30px 0 !important;
	}

	.margin-top-5 {
		margin-top: 5px !important;
	}

	.margin-top-10 {
		margin-top: 10px !important;
	}

	.margin-top-15 {
		margin-top: 15px !important;
	}

	.margin-top-20 {
		margin-top: 20px !important;
	}

	.margin-top-50 {
		margin-top: 50px !important;
	}

	.margin-bottom-5 {
		margin-bottom: 5px !important;
	}

	.margin-bottom-10 {
		margin-bottom: 10px !important;
	}

	.desktop-margin-bottom-10 {
		margin-bottom: 10px !important;
	}

	.margin-bottom-15 {
		margin-bottom: 15px !important;
	}

	.margin-bottom-20 {
		margin-bottom: 20px !important;
	}

	.margin-bottom-25 {
		margin-bottom: 25px !important;
	}

	.margin-bottom-30 {
		margin-bottom: 30px !important;
	}

	.margin-bottom-40 {
		margin-bottom: 40px !important;
	}

	.margin-right-5 {
		margin-right: 5px !important;
	}

	.margin-right-10 {
		margin-right: 10px !important;
	}

	.desktop-margin-right-10 {
		margin-right: 10px !important;
	}

	.margin-right-15 {
		margin-right: 15px !important;
	}

	.margin-right-20 {
		margin-right: 20px !important;
	}

	.desktop-margin-right-25 {
		margin-right: 25px !important;
	}

	.margin-right-30 {
		margin-right: 30px !important;
	}

	.margin-right-40 {
		margin-right: 40px !important;
	}

	.margin-right-50 {
		margin-right: 50px !important;
	}

	.margin-right-60 {
		margin-right: 60px !important;
	}

	.margin-right-5vw {
		margin-right: 5vw !important;
	}

	.margin-right-15vw {
		margin-right: 15vw !important;
	}

	.margin-left-5 {
		margin-left: 5px !important;
	}

	.margin-left-10 {
		margin-left: 10px !important;
	}

	.margin-left-15 {
		margin-left: 15px !important;
	}

	.margin-left-20 {
		margin-left: 20px !important;
	}

	.margin-left-30 {
		margin-left: 30px !important;
	}

	.desktop-margin-left-30 {
		margin-left: 30px !important;
	}

	.margin-left-40 {
		margin-left: 40px !important;
	}

	.margin-left-50 {
		margin-left: 50px !important;
	}

	.margin-left-60 {
		margin-left: 60px !important;
	}

	.margin-left-80 {
		margin-left: 80px !important;
	}

	.margin-left-100 {
		margin-left: 100px !important;
	}

	.margin-left-5vw {
		margin-left: 5vw !important;
	}

	.margin-left-15vw {
		margin-left: 15vw !important;
	}

	.padding-small {
		padding: 10px 15px !important;
	}

	.padding-small-no-bottom {
		padding: 10px 15px 0 10px !important;
	}

	.padding-medium {
		padding: 25px 15px !important;
	}

	.padding-top-2 {
		padding-top: 2px !important;
	}

	.padding-top-5 {
		padding-top: 5px !important;
	}

	.padding-top-8 {
		padding-top: 8px !important;
	}

	.padding-top-9 {
		padding-top: 9px !important;
	}

	.padding-top-10 {
		padding-top: 10px !important;
	}

	.padding-top-15 {
		padding-top: 15px !important;
	}

	.padding-top-20 {
		padding-top: 20px !important;
	}

	.desktop-padding-top-20 {
		padding-top: 20px !important;
	}

	.padding-top-30 {
		padding-top: 30px !important;
	}

	.padding-top-40 {
		padding-top: 40px !important;
	}

	.padding-top-60 {
		padding-top: 60px !important;
	}

	.padding-bottom-0 {
		padding-bottom: 0 !important;
	}

	.padding-bottom-2 {
		padding-bottom: 2px !important;
	}

	.padding-bottom-5 {
		padding-bottom: 5px !important;
	}

	.padding-bottom-8 {
		padding-bottom: 8px !important;
	}

	.padding-bottom-9 {
		padding-bottom: 9px !important;
	}

	.padding-bottom-10 {
		padding-bottom: 10px !important;
	}

	.desktop-padding-bottom-10 {
		padding-bottom: 10px;
	}

	.padding-bottom-15 {
		padding-bottom: 15px !important;
	}

	.padding-bottom-20 {
		padding-bottom: 20px !important;
	}

	.desktop-padding-bottom-20 {
		padding-bottom: 20px !important;
	}

	.padding-bottom-30 {
		padding-bottom: 30px !important;
	}

	.padding-bottom-40 {
		padding-bottom: 40px !important;
	}

	.padding-bottom-80 {
		padding-bottom: 80px !important;
	}

	.padding-left-2 {
		padding-left: 2px !important;
	}

	.padding-left-5 {
		padding-left: 5px !important;
	}

	.padding-left-10 {
		padding-left: 10px !important;
	}

	.padding-left-15 {
		padding-left: 15px !important;
	}

	.padding-left-20 {
		padding-left: 20px !important;
	}

	.desktop-padding-left-20 {
		padding-left: 20px !important;
	}

	.padding-left-30 {
		padding-left: 30px !important;
	}

	.padding-left-40 {
		padding-left: 40px !important;
	}

	.padding-left-50 {
		padding-left: 50px !important;
	}

	.padding-left-100 {
		padding-left: 100px !important;
	}

	.padding-right-2 {
		padding-right: 2px !important;
	}

	.padding-right-5 {
		padding-right: 5px !important;
	}

	.padding-right-10 {
		padding-right: 10px !important;
	}

	.padding-right-15 {
		padding-right: 15px !important;
	}

	.padding-right-20 {
		padding-right: 20px !important;
	}

	.padding-right-30 {
		padding-right: 30px !important;
	}

	.padding-right-40 {
		padding-right: 40px !important;
	}

	.padding-right-50 {
		padding-right: 50px !important;
	}

	.padding-right-100 {
		padding-right: 100px !important;
	}
}

.stemi-mobile {

	.margin-small {
		margin: 10px !important;
	}

	.margin-bottom-small {
		margin: 0 0 10px !important;
	}

	.margin-bottom-top-small {
		margin: 10px 0 !important;
	}

	.margin-bottom-small-right-medium {
		margin: 0 4.17vw 10px 0 !important;
	}

	.margin-bottom-small-right-large {
		margin: 0 8.88vw 10px 0 !important;
	}

	.margin-bottom-medium {
		margin: 0 0 15px !important;
	}

	.margin-bottom-top-medium {
		margin: 15px 0 !important;
	}

	.margin-bottom-large {
		margin: 0 0 20px !important;
	}

	.margin-top-5 {
		margin-top: 5px !important;
	}

	.margin-top-10 {
		margin-top: 10px !important;
	}

	.margin-top-20 {
		margin-top: 20px !important;
	}

	.margin-bottom-5 {
		margin-bottom: 5px !important;
	}

	.margin-bottom-10 {
		margin-bottom: 10px !important;
	}

	.mobile-margin-bottom-10 {
		margin-bottom: 10px !important;
	}

	.margin-bottom-15 {
		margin-bottom: 15px !important;
	}

	.margin-bottom-20 {
		margin-bottom: 20px !important;
	}

	.margin-right-5 {
		margin-right: 1.39vw !important;
	}

	.mobile-margin-right-5 {
		margin-right: 1.39vw !important;
	}

	.margin-right-10 {
		margin-right: 2.78vw !important;
	}

	.mobile-margin-right-10 {
		margin-right: 2.78vw !important;
	}

	.margin-right-15 {
		margin-right: 4.17vw !important;
	}

	.margin-right-20 {
		margin-right: 5.55vw !important;
	}

	.mobile-margin-right-15 {
		margin-right: 4.17vw !important;
	}

	.margin-right-30 {
		margin-right: 8.33vw !important;
	}

	.margin-left-5 {
		margin-left: 1.39vw !important;
	}

	.margin-left-10 {
		margin-left: 2.78vw !important;
	}

	.margin-left-15 {
		margin-left: 4.17vw !important;
	}

	.margin-left-30 {
		margin-left: 8.33vw !important;
	}

	.padding-very-small {
		padding: 5px 2.77vw !important;
	}

	.padding-small {
		padding: 10px 4.17vw !important;
	}

	.padding-small-no-bottom {
		padding: 10px 4.17vw 0 4.17vw !important;
	}

	.padding-top-0 {
		padding-top: 0 !important;
	}

	.padding-top-5 {
		padding-top: 5px !important;
	}

	.padding-top-10 {
		padding-top: 10px !important;
	}

	.mobile-padding-top-10 {
		padding-top: 10px !important;
	}

	.padding-top-15 {
		padding-top: 15px !important;
	}

	.padding-top-20 {
		padding-top: 20px !important;
	}

	.padding-bottom-5 {
		padding-bottom: 5px !important;
	}

	.padding-bottom-10 {
		padding-bottom: 10px !important;
	}

	.mobile-padding-bottom-10 {
		padding-bottom: 10px !important;
	}

	.padding-bottom-15 {
		padding-bottom: 15px !important;
	}

	.padding-bottom-20 {
		padding-bottom: 20px !important;
	}

	.padding-left-10 {
		padding-left: 2.78vw !important;
	}

	.padding-left-15 {
		padding-left: 4.17vw !important;
	}

	.padding-right-10 {
		padding-right: 2.78vw !important;
	}

	.padding-right-15 {
		padding-right: 4.17vw !important;
	}

	.top-0 {
		top: 0 !important;
	}

	.right-0 {
		right: 0 !important;
	}
}

// Positions alignments styles

.width-0px {
	width: 0 !important;
}

.width-7px {
	width: 7px !important;
}

.width-12px {
	width: 12px !important;
}

.width-14px {
	width: 14px !important;
}

.width-15px {
	width: 15px !important;
}

.width-16px {
	width: 16px !important;
}

.width-20px {
	width: 20px !important;
}

.width-25px {
	width: 25px !important;
}

.width-30px {
	width: 30px !important;
}

.width-100px {
	width: 100px !important;
}

.width-150px {
	width: 150px !important;
}

.width-250px {
	width: 250px !important;
}

.width-320px {
	width: 320px !important;
}

.height-15px {
	height: 15px !important;
}

.vertical-align-middle {
	vertical-align: middle !important;
}

.display-none {
	display: none !important;
}

.display-block {
	display: block !important;
}

.display-inline {
	display: inline !important;
}

.display-inline-flex {
	display: inline-flex !important;
}

.display-inline-block {
	display: inline-block !important;
}

.position-relative {
	position: relative !important;
}

.position-absolute {
	position: absolute !important;
}

.z-index-1000 {
	z-index: 1000 !important;
}

.opacity-5 {
	opacity: 0.5 !important;
}

.no-select {
	-webkit-user-select: none !important;
	-moz-user-select: none !important;
	-ms-user-select: none !important;
	user-select: none !important;
}

.visibility-hidden {
	visibility: hidden !important;
}

// Item

.blank-item-mystemi {
	padding: 0 !important;
	--min-height: initial !important;
	--inner-padding-start: 0 !important;
	--inner-padding-end: 0 !important;
	--inner-padding-bottom: 0 !important;
	--inner-padding-top: 0 !important;
	--padding-start: 0 !important;
	--padding-end: 0 !important;
	--padding-bottom: 0 !important;
	--padding-top: 0 !important;
	--highlight-color-focused: transparent !important;
	--highlight-color-invalid: transparent !important;
	--highlight-color-valid: transparent !important;
	--ripple-color: transparent !important;
	--background-hover: transparent !important;
	--background-activated: transparent !important;
	--background-focused: transparent !important;
	--background: transparent;
}

// Checkbox

.checkbox-mystemi {
	--border-color: var(--black) !important;
	--border-color-checked: var(--twilight-blue) !important;
	--background: var(--white) !important;
	--background-checked: var(--twilight-blue) !important;
	--checkmark-color: transparent !important;
	--border-radius: 1px !important;
	--size: 14px !important;
	margin-top: 0 !important;
	margin-bottom: 0 !important;
	align-self: start !important;
}

// Inputs

input, textarea {
	caret-color: var(--black) !important;
}

.stemi-desktop {

	.input-mystemi {
		min-height: 0 !important;
		padding: 0 0 10px !important;

		input {
			padding: 0 !important;
		}
	}

	.textarea-mystemi {
		min-height: 0 !important;
		--padding-top: 0;
		--padding-bottom: 0;
		padding: 0 0 10px;
		margin: 0 !important;
	}

	.input-date-mystemi {
		min-height: 0 !important;
		padding: 0 0 8px !important;
		border-top: none !important;
		border-left: none !important;
		border-right: none !important;
		background: transparent;
		cursor: pointer;
	}

	.input-date-calendar-mystemi {
		min-height: 0 !important;
		padding: 0 0 8px;
		border-top: none !important;
		border-left: none !important;
		border-right: none !important;
		background: url(/assets/imgs/calendrier.svg) no-repeat !important;
		background-position: bottom 10px right 10px !important;
		background-size: 15px !important;
		cursor: pointer;
	}

	.select-pagination-mystemi {
		background: transparent !important;
		padding: 0 !important;
		min-height: 0 !important;
		--highlight-color-focused: transparent !important;
		--highlight-color-invalid: transparent !important;
		--highlight-color-valid: transparent !important;
		--ripple-color: transparent !important;
		white-space: pre-wrap !important;
	}

	.select-mystemi {
		background: transparent !important;
		padding: 0 0 8px !important;
		min-height: 0 !important;
		--highlight-color-focused: transparent !important;
		--highlight-color-invalid: transparent !important;
		--highlight-color-valid: transparent !important;
		--ripple-color: transparent !important;
		white-space: pre-wrap !important;
	}

	.popover-select-mystemi {
		--max-height: 70%;
		--width: fit-content !important;
		--max-width: 30vw !important;

		.popover-viewport {

			.item {
				font-family: 'Inter-Medium';
				font-size: 13px;
				color: var(--black);
				border-bottom: 1px solid var(--very-light-pink) !important;
				--background: var(--white) !important;
				--background-activated: var(--white) !important;
				--background-activated-opacity: 1 !important;
				--highlight-color-focused: var(--white) !important;
				--highlight-color-invalid: var(--white) !important;
				--highlight-color-valid: var(--white) !important;
				--background-focused: var(--white) !important;
				--background-focused-opacity: 1 !important;
				--background-hover: var(--white) !important;
				--background-hover-opacity: 1 !important;
				--inner-border-width: 0 !important;
				--color-focused: var(--black) !important;
				--color-hover: var(--black) !important;
				--indicator-color: transparent !important;
				--ripple-color: transparent !important;
			}

			.item::part(native) {
				ion-label {
					white-space: pre-wrap !important;
				}
			}

			.item:last-of-type {
				border-bottom: none !important;
			}

			ion-radio::part(container) {
				opacity: 1 !important;
			}
		}
	}

	.ion-selectable-mystemi {
		--min-height: 0 !important;
		--inner-padding-start: 0 !important;
		--inner-padding-end: 0 !important;
		--inner-padding-bottom: 0 !important;
		--inner-padding-top: 0 !important;
		--padding-start: 0 !important;
		--padding-end: 0 !important;
		--padding-bottom: 10px !important;
		--padding-top: 0 !important;
		--highlight-color-focused: transparent !important;
		--highlight-color-invalid: transparent !important;
		--highlight-color-valid: transparent !important;
		--background: transparent;

		.ionic-selectable-value {
			padding-top: 0 !important;
			padding-bottom: 0 !important;
		}

		.ionic-selectable-icon-inner {
			top: 5px !important;
			color: var(--black);
		}
	}

	.radio-mystemi {
		--color-checked: var(--twilight-blue);
		--border-radius: 20px;
		--inner-border-radius: 20px;
		width: 15px !important;
		height: 15px !important;
	}
}

// Ionic selectable modal

.stemi-desktop {

	.ion-selectable-modal-mystemi::part(content) {
		box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1) !important;
		border-radius: 4px !important;
		background-color: var(--white);
	}

	.ion-selectable-modal-width-400px-mystemi::part(content) {
		width: 400px !important;
	}

	.ion-selectable-modal-width-800px-mystemi::part(content) {
		width: 700px !important;
	}

	.ion-selectable-modal-mystemi {

		ion-header {
			ion-toolbar {
				--background-color: var(--white) !important;
				border: none !important;
				--border-color: transparent !important;

				.img-selectable-modal {
					height: 18px;
					margin: 10px;
				}

				ion-title {
					padding-right: 0;
					padding-top: 20px;
					padding-bottom: 10px;
				}

				ion-searchbar {
					height: 40px !important;
					border-radius: 4px;
					min-height: 40px !important;
					padding: 0;
					margin: auto;
					caret-color: var(--azure) !important;

					.searchbar-input-container {
						border: 1px solid #b0b0b0 !important;
						border-radius: 4px;
						--box-shadow: none;

						.searchbar-search-icon {
							display: none !important;
						}

						.searchbar-input {
							height: 38px !important;
							border-radius: 4px;
							font-size: 16px !important;
							color: var(--black) !important;
							font-weight: normal;
							padding-left: 10px !important;
							background-color: var(--white);
						}
					}
				}
			}

			.toolbar-searchbar {
				padding-left: 20px;
				padding-right: 20px;
			}
		}

		ion-header::after {
			background: none !important;
		}

		ion-content {

			.ionic-selectable-item {
				font-size: 14px !important;
				color: var(--black);
				--border-color: transparent !important;

				ion-icon {
					color: var(--twilight-blue) !important;
					--ion-color-base: var(--twilight-blue) !important;
					vertical-align: middle;
				}

				ion-icon::before {
					vertical-align: middle;
				}

				ion-label {
					border: none !important;
					font-size: 16px !important;
					white-space: normal !important;
				}
			}

			.ionic-selectable-item:hover {
				opacity: 0.6;
			}
		}

		ion-footer {
			text-align: center;

			ion-toolbar {

				padding: 0 !important;
				--border-color: transparent !important;

				ion-col {
					padding: 0 !important;

					ion-button {
						width: 104px !important;
						max-width: 104px !important;
						min-width: 104px !important;
						height: 40px !important;
						font-size: 14px;
						font-weight: bold;
						--color: var(--white);
						--background: var(--azure);
						margin: 20px auto !important;
						text-transform: none;
					}

					ion-button:hover {
						--opacity: 0.8 !important;
					}
				}
			}
		}

		ion-footer::before {
			background: none !important;
		}
	}
}

.fullcalendar-event:focus:after {
	background: none !important;
}

.fc-datagrid-cell-main {
	white-space: pre-wrap !important;
}

.fc-col-header-cell-cushion, .fc-daygrid-day-number, .fc-list-day-text, .fc-list-day-side-text, .fc-timeline-slot-cushion {
	color: var(--black);
}

.fc-resource-timeline {
	background-color: var(--white) !important;
}

// Tooltip

.tippy-arrow {
	display: none !important;
}

.tippy-content {
	box-shadow: 0 0 10px 0 var(--black-20);
	background-color: var(--white);
}
