.stemi-desktop {

	.grid-users-filters {
		width: 750px;
	}

	.grid-infos-filters {
		width: 650px;
	}

	.grid-forms-filters {
		width: 1250px;
	}

	.grid-tasks-filters {
		width: 940px;
	}

	.grid-filters {
		margin-left: 30px;
		margin-bottom: 20px;
		border-radius: 4px;
		box-shadow: 0 2px 4px 0 var(--very-light-pink);
		border: solid 1px var(--very-light-pink);

		.input-filters {
			height: 32px;
			width: 268px;
			max-width: 268px;
			border-radius: 4px;
			border: solid 1px #b0b0b0;
			background-color: var(--white);
			z-index: 0;
		}

		.datetime-filters {
			height: 32px;
			width: 160px;
			border-radius: 4px;
			border: solid 1px #b0b0b0;
			background-color: var(--white);
			background: url(../assets/imgs/nouveau-date.svg) no-repeat !important;
			background-size: 11% !important;
			background-position: center right 5% !important;
			padding-left: 9px;
		}

		.datetime-filters:hover {
			cursor: pointer;
		}

		.button-filters {
			width: 125px;
			height: 34px;
			border-radius: 4px;
			margin: 0 15px;

			.span-filters {
				vertical-align: middle;
			}
		}

		.button-filters:hover {
			opacity: 0.8;
		}

		.button-clear-filters {
			border: solid 1px var(--greyish-brown);
		}

		.select-filters {
			width: 268px;
		}
	}
}

.stemi-mobile {

	.div-wrapper-filters {
		padding: 0 4.17vw;

		.grid-filters {
			padding: 0 4.17vw;
			border-radius: 4px;
			box-shadow: 0 2px 4px 0 var(--very-light-pink);
			border: solid 1px var(--very-light-pink);

			.input-filters {
				height: 34px;
				width: 83.33vw;
				max-width: 83.33vw;
				border-radius: 4px;
				border: solid 1px #b0b0b0;
				background-color: var(--white);
				z-index: 0;
			}

			.datetime-filters {
				height: 34px;
				width: 36.94vw;
				border-radius: 4px;
				border: solid 1px #b0b0b0;
				background-color: var(--white);
				position: relative !important;
				padding-left: 2.5vw !important;
				background: url(../assets/imgs/nouveau-date.svg) no-repeat !important;
				background-size: 4vw !important;
				background-position: center right 1.5vw !important;
				z-index: 0;
			}

			.datetime-filters input {
				padding-inline-start: 0 !important;
				padding-inline-end: 0 !important;
			}

			.button-filters {
				width: 28.94vw;
				height: 40px;
				border-radius: 4px;
				margin: 0 2.78vw;

				.span-filters {
					vertical-align: middle;
				}
			}

			.button-clear-filters {
				border: solid 1px var(--greyish-brown);
			}

			.select-filters {
				width: 83.33vw;
			}
		}
	}
}

.stemi-mobile, .plt-android {
	.icon-filters {
		font-size: 22px !important;
	}
}

.stemi-mobile, .plt-ios {
	.icon-filters {
		font-size: 22px !important;
	}
}
