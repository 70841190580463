// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
	/** primary **/
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255, 255, 255;
	--ion-color-primary-shade: #3171e0;
	--ion-color-primary-tint: #4c8dff;

	/** secondary **/
	--ion-color-secondary: #3dc2ff;
	--ion-color-secondary-rgb: 61, 194, 255;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255, 255, 255;
	--ion-color-secondary-shade: #36abe0;
	--ion-color-secondary-tint: #50c8ff;

	/** tertiary **/
	--ion-color-tertiary: #5260ff;
	--ion-color-tertiary-rgb: 82, 96, 255;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255, 255, 255;
	--ion-color-tertiary-shade: #4854e0;
	--ion-color-tertiary-tint: #6370ff;

	/** success **/
	--ion-color-success: #2dd36f;
	--ion-color-success-rgb: 45, 211, 111;
	--ion-color-success-contrast: #ffffff;
	--ion-color-success-contrast-rgb: 255, 255, 255;
	--ion-color-success-shade: #28ba62;
	--ion-color-success-tint: #42d77d;

	/** warning **/
	--ion-color-warning: #ffc409;
	--ion-color-warning-rgb: 255, 196, 9;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0, 0, 0;
	--ion-color-warning-shade: #e0ac08;
	--ion-color-warning-tint: #ffca22;

	/** danger **/
	--ion-color-danger: #eb445a;
	--ion-color-danger-rgb: 235, 68, 90;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255, 255, 255;
	--ion-color-danger-shade: #cf3c4f;
	--ion-color-danger-tint: #ed576b;

	/** dark **/
	--ion-color-dark: #222428;
	--ion-color-dark-rgb: 34, 36, 40;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255, 255, 255;
	--ion-color-dark-shade: #1e2023;
	--ion-color-dark-tint: #383a3e;

	/** medium **/
	--ion-color-medium: #92949c;
	--ion-color-medium-rgb: 146, 148, 156;
	--ion-color-medium-contrast: #ffffff;
	--ion-color-medium-contrast-rgb: 255, 255, 255;
	--ion-color-medium-shade: #808289;
	--ion-color-medium-tint: #9d9fa6;

	/** light **/
	--ion-color-light: #f4f5f8;
	--ion-color-light-rgb: 244, 245, 248;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0, 0, 0;
	--ion-color-light-shade: #d7d8da;
	--ion-color-light-tint: #f5f6f9;

	--main-color: #00adef;
	--second-color: #DDEBF6;
	--third-color: #F4FAFF;
	--black-color: #191919;

	--ion-color-base: var(--main-color) !important;
	--ion-color-base-rbg: var(--main-color) !important;
	--ion-color-primary: var(--main-color) !important;
	--ion-color-primary-rgb: var(--main-color) !important;

	--apricot:					#ffb764;
	--azure:					#00adef;
	--azure-5:					#00adef0c;
	--azure-10:					rgba(0, 173, 239, 0.1);
	--azure-20:					rgba(0, 173, 239, 0.2);
	--azure-light:				#0bbae9;
	--baby-blue:				#b2d6ff;
	--black: 					#000000;
	--black-20: 				rgba(0, 0, 0, 0.2);
	--black-21: 				rgba(0, 0, 0, 0.21);
	--black-50: 				rgba(0, 0, 0, 0.5);
	--brownish-grey: 			#727272;
	--brown-grey-two: 			#b0b0b0;
	--brown-grey: 				#979797;
	--buff:						#E8A87C;
	--creme:					#fcffbc;
	--creme-30:					rgba(252, 255, 188, 0.3);
	--duck-egg-blue:			#dff0f9;
	--grapefruit: 				#ff5353;
	--greyish-10:				rgba(175, 175, 175, 0.1);
	--greyish-brown: 			#414141;
	--greyish-brown-5: 			rgba(65, 65, 65, 0.05);
	--greyish-brown-10: 		rgba(65, 65, 65, 0.1);
	--greyish-brown-80: 		rgba(65, 65, 65, 0.8);
	--jordy-blue:				#A3C9F1;
	--light-duck-egg-blue:		#f7fcfe;
	--light-green:				#B8E994;
	--light-grey-green:			#9bdb94;
	--light-khaki:				#f5d4a5;
	--light-peach:				#ffc5c5;
	--light-peach-two:			#ffd5c6;
	--light-pink:				#ffd5e7;
	--light-lavender-20:		#f0a2ff33;
	--light-teal:				#a8e3d6;
	--light-teal-two:			#9be7d9;
	--pale-blue:				#f4fbfe;
	--pale-grey:				#f7f7fa;
	--pale-salmon:				#ffb390;
	--pale-salmon-two:			#ffc78e;
	--parchement:				#fff3ae;
	--pastel-purple:			#cc99ff;
	--peach:					#ffc885;
	--peacock-blue:				#00599b;
	--powder-blue:				#afe6fb;
	--powder-blue-two:			#afe6fb;
	--putty:					#d3cca6;
	--robin-egg-blue:			#8adfff;
	--sand:						#cce970;
	--sandy-yellow:				#ffef75;
	--seafoam-blue:				#55cac3;
	--sickly-yellow:			#d6df23;
	--sun-yellow:				#ffdf36;
	--sun-yellowish:			#ffd31b;
	--sunshine-yellow:			#ffe83c;
	--tea-green:				#d1f8b0;
	--teal-green:				#259972;
	--turtle-green: 			#7ebf57;
	--turtle-green-two: 		#7ebf57;
	--twilight-blue:			#0c4d98;
	--twilight-blue-10:			rgba(12, 77, 152, 0.1);
	--twilight-blue-30:			rgba(12, 77, 152, 0.3);
	--vanilla:					#F9E79F;
	--very-light-pink: 			#e1e1e1;
	--very-light-pink-two: 		#f0f0f0;
	--very-light-pink-three: 	#c8c8c8;
	--very-light-purple:		#fde2f5;
	--warm-grey:				#8c8c8c;
	--white: 					#ffffff;
	--white-50: 				rgba(255, 255, 255, 0.5);
	--white-grey:				#ececec;
	--white-grey-2:				#e7e7e7;
	--white-smoke:				#F2F2F2;
	--yellowish-orange: 		#fe9c15;

	--ion-font-family: 'MyRoboto' !important;
}

:focus {
	outline: none !important;
}
