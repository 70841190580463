// Text styles Desktop

.stemi-desktop {

	.desktop-text-style-1 {
		font-size: 13px !important;
		color: var(--brownish-grey) !important;
	}

	.desktop-text-style-2 {
		font-size: 14px !important;
		font-weight: bold !important;
		color: var(--white) !important;
	}

	.desktop-text-style-3 {
		font-size: 23px !important;
		font-weight: bold !important;
		color: var(--greyish-brown) !important;
	}

	.desktop-text-style-4 {
		font-size: 20px !important;
		font-weight: bold !important;
		color: var(--white) !important;
	}

	.desktop-text-style-5 {
		font-size: 16px !important;
		font-weight: bold !important;
		color: var(--white) !important;
	}

	.desktop-text-style-6 {
		font-size: 15px !important;
		color: var(--sickly-yellow) !important;
	}

	.desktop-text-style-7 {
		font-size: 15px !important;
		color: var(--greyish-brown) !important;
	}

	.desktop-text-style-8 {
		font-size: 15px !important;
		color: var(--greyish-brown) !important;
		letter-spacing: 0 !important;
	}

	.desktop-text-style-9 {
		font-size: 15px !important;
		color: var(--greyish-brown) !important;
	}

	.desktop-text-style-10 {
		font-size: 15px !important;
		color: var(--greyish-brown) !important;
	}

	.desktop-text-style-11 {
		font-size: 13px !important;
		font-weight: bold !important;
		text-align: center !important;
		color: var(--white) !important;
	}

	.desktop-text-style-12 {
		font-size: 18px !important;
		font-weight: bold !important;
		color: var(--greyish-brown) !important;
	}

	.desktop-text-style-13 {
		font-size: 13px !important;
		color: var(--greyish-brown) !important;
	}

	.desktop-text-style-13-white {
		font-size: 13px !important;
		color: var(--white) !important;
	}

	.desktop-text-style-14 {
		font-size: 13px !important;
		font-style: italic !important;
		color: var(--greyish-brown) !important;
	}

	.desktop-text-style-15 {
		font-size: 16px !important;
		font-weight: bold;
		color: var(--black) !important;
	}

	.desktop-text-style-16 {
		font-size: 25px !important;
		font-weight: bold !important;
		color: var(--twilight-blue) !important;
	}

	.desktop-text-style-17 {
		font-size: 15px !important;
		color: var(--black) !important;
	}

	.desktop-text-style-18 {
		font-size: 23px !important;
		font-weight: bold !important;
		text-align: right !important;
		color: var(--turtle-green) !important;
	}

	.desktop-text-style-19 {
		font-size: 23px !important;
		font-weight: bold !important;
		text-align: right !important;
		color: var(--grapefruit) !important;
	}

	.desktop-text-style-19-orange {
		font-size: 23px !important;
		font-weight: bold !important;
		text-align: right !important;
		color: var(--yellowish-orange) !important;
	}

	.desktop-text-style-20 {
		font-size: 15px !important;
		color: var(--black) !important;
	}

	.desktop-text-style-21 {
		font-size: 25px !important;
		font-weight: bold !important;
		color: var(--greyish-brown) !important;
	}

	.desktop-text-style-22 {
		font-size: 14px !important;
		font-weight: bold !important;
		color: var(--greyish-brown) !important;
		letter-spacing: 0 !important;
	}

	.desktop-text-style-23 {
		font-size: 12px !important;
		color: var(--greyish-brown) !important;
		letter-spacing: 0 !important;
	}

	.desktop-text-style-24 {
		font-size: 12px !important;
		color: var(--greyish-brown) !important;
	}

	.desktop-text-style-25 {
		font-size: 16px !important;
		color: var(--greyish-brown) !important;
	}

	.desktop-text-style-26 {
		font-size: 16px !important;
		font-weight: bold !important;
		color: var(--greyish-brown) !important;
	}

	.desktop-text-style-27 {
		font-size: 12px !important;
		font-style: italic !important;
		color: #585858 !important;
	}

	.desktop-text-style-28 {
		font-size: 15px !important;
		font-weight: bold !important;
		color: var(--greyish-brown) !important;
	}

	.desktop-text-style-29 {
		font-size: 14px !important;
		font-weight: bold !important;
		color: var(--greyish-brown) !important;
	}

	.desktop-text-style-30 {
		font-size: 16px !important;
		color: var(--brownish-grey) !important;
	}

	.desktop-text-style-31 {
		font-size: 15px !important;
		color: var(--brownish-grey) !important;
	}

	.desktop-text-style-32 {
		font-size: 14px !important;
		font-weight: bold !important;
		color: var(--azure) !important;
	}

	.desktop-text-style-33 {
		font-size: 14px !important;
		color: var(--brownish-grey) !important;
	}

	.text-style-missing {
		font-size: 13px !important;
		color: var(--grapefruit) !important;
	}

	.desktop-text-style-blue-indicator {
		font-size: 15px !important;
		font-weight: bold;
		color: var(--twilight-blue) !important;
	}

	.desktop-text-style-warning {
		font-size: 16px !important;
		font-weight: bold !important;
		color: var(--grapefruit) !important;
	}
}

.stemi-mobile {

	.mobile-text-style-1 {
		font-size: 13px !important;
		color: var(--brownish-grey) !important;
	}

	.mobile-text-style-2 {
		font-size: 14px !important;
		font-weight: bold;
		color: var(--white) !important;
	}

	.mobile-text-style-3 {
		font-size: 16px !important;
		color: var(--greyish-brown) !important;
	}

	.mobile-text-style-4 {
		font-size: 20px !important;
		font-weight: bold !important;
		color: var(--greyish-brown) !important;
	}

	.mobile-text-style-5 {
		font-size: 13px !important;
		font-weight: bold !important;
		color: var(--white) !important;
	}

	.mobile-text-style-6 {
		font-size: 18px !important;
		font-weight: bold !important;
		color: var(--white) !important;
	}

	.mobile-text-style-7 {
		font-size: 16px !important;
		font-weight: bold !important;
		color: var(--greyish-brown) !important;
	}

	.mobile-text-style-8 {
		font-size: 13px !important;
		color: var(--greyish-brown) !important;
	}

	.mobile-text-style-9 {
		font-size: 13px !important;
		font-style: italic !important;
		color: var(--greyish-brown) !important;
	}

	.mobile-text-style-10 {
		font-size: 16px !important;
		font-weight: bold !important;
		color: var(--black) !important;
	}

	.mobile-text-style-11 {
		font-size: 15px !important;
		text-align: center !important;
		color: var(--greyish-brown) !important;
	}

	.mobile-text-style-12 {
		font-size: 14px !important;
		text-align: center !important;
		color: var(--greyish-brown) !important;
	}

	.mobile-text-style-13 {
		font-size: 10px !important;
		color: var(--sickly-yellow) !important;
	}

	.mobile-text-style-14 {
		font-size: 10px !important;
		color: var(--white) !important;
	}

	.mobile-text-style-15 {
		font-size: 15px !important;
		color: var(--black) !important;
	}

	.mobile-text-style-16 {
		font-size: 25px !important;
		font-weight: bold !important;
		color: var(--greyish-brown) !important;
	}

	.mobile-text-style-17 {
		font-size: 15px !important;
		text-align: center !important;
		color: var(--greyish-brown) !important;
	}

	.mobile-text-style-18 {
		font-size: 14px !important;
		font-weight: bold;
		text-align: right !important;
		color: var(--greyish-brown) !important;
	}

	.mobile-text-style-19 {
		font-size: 12px !important;
		color: var(--greyish-brown) !important;
	}

	.mobile-text-style-20 {
		font-size: 14px !important;
		font-style: italic !important;
		color: var(--brown-grey-two) !important;
	}

	.mobile-text-style-21 {
		font-size: 14px !important;
		color: var(--twilight-blue) !important;
	}

	.mobile-text-style-22 {
		font-size: 16px !important;
		color: var(--greyish-brown) !important;
	}

	.mobile-text-style-23 {
		font-size: 14px !important;
		color: var(--brownish-grey) !important;
	}

	.mobile-text-style-24 {
		font-size: 15px !important;
		font-weight: 600 !important;
		color: var(--greyish-brown) !important;
	}

	.mobile-text-style-25 {
		font-size: 16px !important;
		color: var(--greyish-brown) !important;
	}

	.mobile-text-style-26 {
		font-size: 14px !important;
		color: var(--greyish-brown) !important;
	}

	.mobile-text-style-27 {
		font-size: 16px !important;
		font-weight: bold !important;
		color: var(--white) !important;
	}

	.mobile-text-style-28 {
		font-size: 15px !important;
		font-weight: bold !important;
		color: var(--greyish-brown) !important;
	}

	.mobile-text-style-29 {
		font-size: 15px !important;
		font-weight: bold !important;
		color: var(--greyish-brown) !important;
	}

	.mobile-text-style-30 {
		font-size: 14px !important;
		font-weight: bold;
		color: var(--greyish-brown) !important;
	}

	.mobile-text-style-31 {
		font-size: 15px !important;
		font-weight: 600 !important;
		color: var(--twilight-blue) !important;
	}

	.mobile-text-style-32 {
		font-size: 13px !important;
		font-style: italic !important;
		font-weight: bold !important;
		color: var(--greyish-brown) !important;
	}

	.mobile-text-style-33 {
		font-size: 13px !important;
		color: var(--black) !important;
	}

	.mobile-text-style-34 {
		font-size: 16px !important;
		color: var(--brownish-grey) !important;
	}

	.mobile-text-style-35 {
		font-size: 16px !important;
		color: var(--white) !important;
	}

	.mobile-text-style-36 {
		font-size: 13px !important;
		font-weight: bold !important;
		color: var(--greyish-brown) !important;
	}

	.text-style-missing {
		font-size: 13px !important;
		color: var(--grapefruit) !important;
	}

	.mobile-text-style-indicator-green {
		font-size: 25px !important;
		font-weight: bold !important;
		color: var(--turtle-green-two) !important;
	}

	.mobile-text-style-indicator-orange {
		font-size: 25px !important;
		font-weight: bold !important;
		color: var(--yellowish-orange) !important;
	}

	.mobile-text-style-indicator-red {
		font-size: 25px !important;
		font-weight: bold !important;
		color: var(--grapefruit) !important;
	}

	.mobile-text-style-blue-indicator {
		font-size: 14px !important;
		font-weight: bold;
		color: var(--twilight-blue) !important;
	}

	.mobile-text-style-blue-indicator-big {
		font-size: 25px !important;
		font-weight: bold;
		color: var(--twilight-blue) !important;
	}

	.mobile-text-style-warning {
		font-size: 16px !important;
		font-weight: bold !important;
		color: var(--grapefruit) !important;
	}
}
