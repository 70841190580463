.menu-menu {
	--min-width: 375px !important;
	--width: 375px !important;
	--max-width: 375px !important;

	.header-menu {
		height: 162px !important;
		position: relative;
		background-size: 165px 103px !important;
		background: var(--white) url(../assets/imgs/logo-connexion@3x.png) top 36px center no-repeat;

		.img-logo-menu {
			object-fit: contain;
			height: 100%;
			width: 100%;
			padding: 20px;
		}
	}

	.menu-content {
		.item-menu {
			border-bottom: 1px solid var(--white-50);
		}

		.item-menu, .item-selected-menu {
			.img-item-menu {
				width: 24px;
			}
		}
	}
}
