// Forms header

.stemi-desktop {

	.grid-header-form {
		height: 40px;
		width: 100%;
		padding-left: 30px !important;
		z-index: 9999;

		.row-header-form {
			align-items: center;
			height: 40px;

			.label-header-form {
				display: inline;
			}

			.col-separator-header-form {
				border-left: 2px solid var(--greyish-brown);
				margin: auto 14px;
				height: 28px;
			}

			.img-header-form {
				height: 30px;
				margin-right: 10px;
				vertical-align: middle;
			}

			.img-header-form:hover {
				opacity: 0.6;
			}
		}
	}
}

.stemi-mobile {

	.grid-header-form {
		height: 40px;
		width: 100%;
		padding-left: 4.17vw !important;
		z-index: 9999;

		.row-header-form {
			align-items: center;
			height: 40px;

			.col-save-header-form {
				text-align: right;

				.img-header-form {
					height: 30px;
					vertical-align: middle;
					margin-right: 4.17vw;
				}
			}
		}
	}
}

// Forms content

.stemi-desktop {

	.div-step-form {
		padding-top: 20px;
		padding-left: 30px;

		.div-content-step-form {
			width: 750px;
		}

		.div-content-step-sign-form {
			width: 750px;
		}

		.grid-full-less-width-form {
			width: 720px;
		}

		.grid-full-width-form {
			width: 750px;
		}

		.grid-full-over-width-form {
			width: 868px;
		}

		.border-grey-form {
			border-radius: 4px;
			box-shadow: 0 2px 4px 0 var(--very-light-pink);
			border: solid 1px var(--very-light-pink);
			background-color: var(--white);
		}

		.border-bottom-twilight-blue-form {
			border-bottom: solid 2px var(--twilight-blue);
		}

		.img-small-form {
			height: 40px !important;
		}

		.img-medium-form {
			height: 60px !important;
		}

		.item-title-form {

			ion-checkbox {
				margin-right: 10px !important;
			}
		}

		.item-title-form:hover {
			opacity: 0.6;
		}

		.item-detail-accueil-securite-form {

			--highlight-color-focused: transparent !important;
			--highlight-color-invalid: transparent !important;
			--highlight-color-valid: transparent !important;
			--ripple-color: transparent !important;
			--background-hover: transparent !important;
			--background-activated: transparent !important;
			--background-focused: transparent !important;
			--border-color: transparent;
			--transition: none;

			ion-checkbox {
				margin-right: 10px !important;
			}
		}

		.item-detail-accueil-securite-form:hover {
			opacity: 0.6;
		}

		.item-checkbox-form {
			--highlight-color-focused: transparent !important;
			--highlight-color-invalid: transparent !important;
			--highlight-color-valid: transparent !important;
			--ripple-color: transparent !important;
			--background-hover: transparent !important;
			--background-activated: transparent !important;
			--background-focused: transparent !important;
			--border-color: transparent;
			--transition: none;

			ion-checkbox {
				margin: 0 !important;
			}
		}

		.item-checkbox-form::part(native) {
			padding-inline-start: 0 !important;
		}

		.item-full-width-form {
			width: 719px !important;
			--highlight-color-focused: transparent !important;
			--highlight-color-invalid: transparent !important;
			--highlight-color-valid: transparent !important;
			--ripple-color: transparent !important;
			--background-hover: transparent !important;
			--background-activated: transparent !important;
			--background-focused: transparent !important;
			--border-color: transparent;
			--transition: none;

			ion-checkbox {
				margin-right: 10px !important;
			}
		}

		.item-full-width-form:hover {
			opacity: 0.6;
		}

		.desktop-label-quarter-width-background-grey-form {
			width: 162px;
			border-radius: 4px;
			background-color: var(--very-light-pink-three);
			padding: 8px 0 6px 10px;
			min-height: 32px;
		}

		.desktop-label-half-width-background-grey-form {
			width: 345px;
			border-radius: 4px;
			background-color: var(--very-light-pink-three);
			padding: 8px 0 6px 10px;
			min-height: 32px;
		}

		.desktop-label-half-width-background-azure-form {
			width: 345px;
			border-radius: 4px;
			background-color: var(--azure-10);
			padding: 8px 10px 6px 10px;
			min-height: 32px;
		}

		.desktop-label-full-width-background-grey-form {
			width: 719px;
			border-radius: 4px;
			background-color: var(--very-light-pink-three);
			padding: 8px 0 6px 10px;
			min-height: 32px;
		}

		.label-signed-form {
			display: flex !important;
			align-items: center;
			width: 313px;
			height: 32px;
			min-height: 32px;
			border-radius: 4px;
			border: solid 1px #b0b0b0;
			background-color: var(--very-light-pink);
			padding: 8px 0 6px 10px;

			.img-sign-form {
				margin-right: 8px !important;
				vertical-align: middle;
			}
		}

		.label-signed-form-full-width {
			width: 345px !important;
		}

		.input-form {
			height: 32px;
			border-radius: 4px;
			border: solid 1px #b0b0b0;
			background-color: var(--white);
		}

		.desktop-input-quarter-width-no-wrapper-form {
			width: 161px;
			max-width: 161px;
		}

		.desktop-date-picker-quarter-width-no-wrapper-form {
			width: 161px;
			height: 32px;
			border-radius: 4px;
			border: solid 1px #b0b0b0;
			background-color: var(--white);
			background: url(../assets/imgs/nouveau-date.svg) no-repeat !important;
			background-size: 11% !important;
			background-position: center right 5% !important;
			padding-left: 9px;
		}

		.desktop-date-picker-quarter-width-no-wrapper-form:hover {
			cursor: pointer;
		}

		.desktop-date-picker-quarter-width-no-wrapper-form[disabled] {
			opacity: 0.5;
		}

		.desktop-input-half-width-no-wrapper-form {
			width: 343px;
			max-width: 343px;
		}

		.desktop-date-picker-half-width-no-wrapper-form {
			width: 343px;
			height: 32px;
			border-radius: 4px;
			border: solid 1px #b0b0b0;
			background-color: var(--white);
			background: url(../assets/imgs/nouveau-date.svg) no-repeat !important;
			background-size: 5.5% !important;
			background-position: center right 3% !important;
			padding-left: 9px;
		}

		.desktop-date-picker-half-width-no-wrapper-form:hover {
			cursor: pointer;
		}

		.desktop-input-half-less-width-with-wrapper-form {
			width: 312px;
			max-width: 312px;
		}

		.desktop-input-half-width-with-wrapper-form {
			width: 328px;
			max-width: 328px;
		}

		.desktop-input-full-width-no-wrapper-form {
			width: 719px;
			max-width: 719px;
		}

		.desktop-input-full-width-with-wrapper-form {
			width: 719px;
			max-width: 719px;
		}

		.textarea-form {
			border-radius: 4px;
			border: solid 1px #b0b0b0;
			background-color: var(--white);
		}

		.textarea-half-height-form {
			min-height: 62px;

			.textarea-wrapper {

				textarea {
					min-height: 62px;
				}
			}
		}

		.textarea-full-height-form {
			min-height: 121px;

			.textarea-wrapper {

				textarea {
					min-height: 121px;
				}
			}
		}

		.desktop-textarea-third-width-no-wrapper-form {
			width: 233px;
			max-width: 233px;
		}

		.desktop-textarea-half-width-no-wrapper-form {
			width: 343px;
			max-width: 343px;
		}

		.desktop-textarea-half-width-with-wrapper-form {
			width: 311px;
			max-width: 311px;
		}

		.desktop-textarea-full-width-no-wrapper-form {
			width: 719px;
			max-width: 719px;
		}

		.desktop-textarea-full-over-width-no-wrapper-form {
			width: 868px;
			max-width: 868px;
		}

		.desktop-textarea-full-width-with-wrapper-form {
			width: 719px;
			max-width: 719px;
		}

		.select-form {
			height: 32px !important;
			-webkit-appearance: none;
			border: 1px solid #b0b0b0 !important;
			border-radius: 4px;
			background-color: var(--white);
			background-image: url(../assets/imgs/down-arrow.svg);
			background-position: right 10px center;
			background-repeat: no-repeat;
			background-size: 10px;
			text-indent: 9px;
			--highlight-color-focused: transparent !important;
			--highlight-color-invalid: transparent !important;
			--highlight-color-valid: transparent !important;
			--ripple-color: transparent !important;
			opacity: 1 !important;
		}

		.ion-select-form {
			text-indent: 0 !important;
			--padding-start: 10px;
			--padding-end: 20px;
		}

		.ion-select-form::part(icon) {
			display: none;
		}

		.div-add-file-form {
			height: 32px !important;

			.img-add-file-form {
				height: 20px;
				margin-left: 10px;
				vertical-align: middle;
			}

			.img-add-file-form:hover {
				opacity: 0.6;
			}

			.span-add-file-form {
				vertical-align: middle;
			}
		}

		.div-file-form {
			display: inline-block;
			min-width: 250px;

			.img-file-form {
				height: 200px;
				vertical-align: middle;
			}

			.img-remove-file-form {
				height: 20px;
				margin-left: 10px;
				margin-right: 10px;
				vertical-align: middle;
			}

			.img-remove-file-form:hover {
				opacity: 0.6;
			}
		}

		.div-attachment-form {
			display: inline-block;
			min-width: 750px;

			.label-attachment-form {
				display: inline !important;
				vertical-align: middle;
			}

			.img-remove-attachment-form {
				height: 20px;
				margin-left: 10px;
				vertical-align: middle;
			}
		}

		.img-body-form {
			width: 120px;
			margin-right: 25px;
			margin-bottom: 20px;
			vertical-align: middle;
		}

		.img-body-form:hover {
			opacity: 0.6;
		}

		.div-buttons-navigation-form {
			margin: 30px 0 30px 0;
			display: flex;

			.button-previous-form {
				width: 121px;
				height: 34px;
				border-radius: 4px;
				border: solid 1px var(--greyish-brown);
				background-color: var(--white);
				margin-right: 20px !important;
			}

			.button-previous-form:hover {
				opacity: 0.6;
			}

			.button-next-form {
				width: 121px;
				height: 34px;
				border-radius: 4px;
				background-color: var(--azure);
			}

			.button-next-form:hover {
				opacity: 0.8;
			}
		}

		.div-title-sign-form {
			display: flex;
			align-items: center;
			margin: 0 0 15px !important;

			.span-1-title-sign-form {
				margin-right: 40px;
			}

			.button-title-sign-form {
				padding: 0 !important;
			}

			.button-title-sign-form:hover {
				opacity: 0.6;
			}

			.span-2-title-sign-form {
				margin: 0 20px;
			}
		}

		.img-sign-form {
			height: 20px !important;
		}

		.div-signatory-form {
			width: 343px;
			padding: 15px;
			position: relative;
			display: inline-block;
			margin-right: 30px !important;
			vertical-align: top;

			.button-div-signatory-form {
				position: absolute;
				top: 15px;
				right: 15px;
			}

			.button-div-signatory-form:hover {
				opacity: 0.6;
			}

			.button-to-sign-form {
				width: 81px;
				height: 34px;
				border-radius: 4px;
				box-shadow: 0 2px 4px 0 var(--very-light-pink);
				border: solid 1px var(--azure);
				background-color: var(--white);
			}

			.button-to-sign-form:hover {
				opacity: 0.6;
			}
		}
	}

	.popover-select-form {
		--max-height: 70%;
		--width: fit-content !important;
		--max-width: 30vw !important;

		.popover-viewport {

			ion-list {

				padding-top: 0 !important;
				padding-bottom: 0 !important;

				.item {
					font-size: 16px;
					color: var(--black);
					border-bottom: 1px solid var(--very-light-pink) !important;
					--background: var(--white);
					--background-activated: var(--white) !important;
					--background-activated-opacity: 1 !important;
					--highlight-color-focused: var(--white) !important;
					--highlight-color-invalid: var(--white) !important;
					--highlight-color-valid: var(--white) !important;
					--background-focused: var(--white) !important;
					--background-focused-opacity: 0 !important;
					--background-hover: var(--white) !important;
					--background-hover-opacity: 0 !important;
					--inner-border-width: 0 !important;
					--color-focused: var(--black) !important;
					--color-hover: var(--black) !important;
					--indicator-color: transparent !important;
					--ripple-color: transparent !important;
				}

				.item-radio-checked {
					--background: var(--azure) !important;

					ion-label {
						color: var(--white) !important;
					}
				}

				.item::part(native) {
					ion-label {
						white-space: pre-wrap !important;
					}
				}

				.item:last-of-type {
					border-bottom: none !important;
				}

				ion-radio::part(container) {
					opacity: 1 !important;
				}
			}
		}
	}

	// buttons

	.button-right-div {
		position: absolute !important;
		right: -50px !important;
		top: 50% !important;
		transform: translateY(-50%) !important;
	}
}

.stemi-mobile {

	.div-step-form {
		padding: 10px 4.17vw 0 4.17vw;

		.div-content-step-form {
			padding: 0 4.17vw;
		}

		.border-grey-form {
			border-radius: 4px;
			box-shadow: 0 2px 4px 0 var(--very-light-pink);
			border: solid 1px var(--very-light-pink);
			background-color: var(--white);
		}

		.border-bottom-twilight-blue-form {
			border-bottom: solid 1px var(--twilight-blue);
		}

		.img-small-form {
			height: 40px !important;
		}

		.img-medium-form {
			height: 60px !important;
		}

		.item-title-form {

			.item-inner {
				padding-right: 0 !important;
			}
		}

		.item-detail-accueil-securite-form {

			--highlight-color-focused: transparent !important;
			--highlight-color-invalid: transparent !important;
			--highlight-color-valid: transparent !important;
			--ripple-color: transparent !important;
			--background-hover: transparent !important;
			--background-activated: transparent !important;
			--background-focused: transparent !important;
			--border-color: transparent;
			--transition: none;

			.item-inner {
				padding-right: 0 !important;
			}
		}

		.item-checkbox-form {
			--highlight-color-focused: transparent !important;
			--highlight-color-invalid: transparent !important;
			--highlight-color-valid: transparent !important;
			--ripple-color: transparent !important;
			--background-hover: transparent !important;
			--background-activated: transparent !important;
			--background-focused: transparent !important;
			--border-color: transparent;
			--transition: none;

			ion-checkbox {
				margin: 0 !important;
			}
		}

		.item-checkbox-form::part(native) {
			padding-inline-start: 0 !important;
		}

		.item-full-width-form {

			--highlight-color-focused: transparent !important;
			--highlight-color-invalid: transparent !important;
			--highlight-color-valid: transparent !important;
			--ripple-color: transparent !important;
			--background-hover: transparent !important;
			--background-activated: transparent !important;
			--background-focused: transparent !important;
			--border-color: transparent;
			--transition: none;

			.item-inner {
				padding-right: 0 !important;
			}

			ion-checkbox {
				margin-right: 0 !important;
			}
		}

		.mobile-label-half-width-background-grey-form {
			width: 36.94vw;
			border-radius: 4px;
			border: solid 1px var(--brown-grey-two);
			background-color: var(--very-light-pink);
			padding: 6px 0 6px 2.5vw;
			min-height: 34px;
		}

		.mobile-label-full-width-background-grey-form {
			width: 82.78vw;
			border-radius: 4px;
			border: solid 1px var(--brown-grey-two);
			background-color: var(--very-light-pink);
			padding: 6px 0 6px 2.5vw;
			min-height: 34px;
		}

		.label-signed-form {
			display: flex !important;
			align-items: center;
			width: 82.78vw;
			height: 34px;
			min-height: 34px;
			border-radius: 4px;
			border: solid 1px var(--brown-grey-two);
			background-color: var(--very-light-pink);
			padding: 6px 0 6px 2.5vw;

			.img-sign-form {
				margin-right: 2.22vw !important;
				vertical-align: middle;
			}
		}

		.input-form {
			height: 34px;
			border-radius: 4px;
			border: solid 1px #b0b0b0;
			background-color: var(--white);
		}

		.mobile-input-half-width-no-wrapper-form {
			width: 36.94vw !important;
			max-width: 36.94vw !important;
		}

		.datetime-half-width-no-wrapper-form {
			padding-left: 2.5vw !important;
			background: url(../assets/imgs/nouveau-date.svg) no-repeat !important;
			background-size: 4vw !important;
			background-position: center right 1.5vw !important;
			height: 34px;
		}

		.datetime-half-width-no-wrapper-form input {
			padding-inline-start: 0 !important;
			padding-inline-end: 0 !important;
		}

		.mobile-input-full-width-no-wrapper-form {
			width: 83.33vw;
			max-width: 83.33vw;
		}

		.datetime-full-width-no-wrapper-form {
			padding-left: 2.5vw !important;
			background: url(../assets/imgs/nouveau-date.svg) no-repeat !important;
			background-size: 4vw !important;
			background-position: center right 1.5vw !important;
			height: 34px;
		}

		.datetime-full-width-no-wrapper-form input {
			padding-inline-start: 0 !important;
			padding-inline-end: 0 !important;
		}

		.mobile-input-full-width-with-wrapper-form {
			width: 71.5vw;
			max-width: 71.5vw;
		}

		.textarea-form {
			border-radius: 4px;
			border: solid 1px #b0b0b0;
			background-color: var(--white);
			min-height: 50px !important;

			.textarea-wrapper {

				textarea {
					min-height: 50px;
				}
			}
		}

		.mobile-textarea-third-width-form {
			width: 60.55vw !important;
			max-width: 60.55vw !important;
		}

		.select-form {
			height: 32px !important;
			-webkit-appearance: none;
			border: 1px solid #b0b0b0 !important;
			border-radius: 4px;
			background-color: var(--white);
			background-image: url(../assets/imgs/down-arrow.svg);
			background-position: right 10px center;
			background-repeat: no-repeat;
			background-size: 10px;
			text-indent: 9px;
			--highlight-color-focused: transparent !important;
			--highlight-color-invalid: transparent !important;
			--highlight-color-valid: transparent !important;
			--ripple-color: transparent !important;
			opacity: 1 !important;
		}

		.ion-select-form {
			text-indent: 0 !important;
			--padding-start: 10px;
			--padding-end: 20px;
		}

		.ion-select-form::part(icon) {
			display: none;
		}

		.div-add-file-form {
			height: 34px !important;

			.img-add-file-form {
				height: 20px;
				margin-left: 2.78vw;
				vertical-align: middle;
			}

			.span-add-file-form {
				vertical-align: middle;
			}
		}

		.datetime-form {
			position: relative !important;
		}

		.div-file-form {
			display: inline-block;
			min-width: 40vw;
			text-align: center;

			.img-file-form {
				width: 25vw;
				vertical-align: middle;
			}

			.img-remove-file-form {
				height: 20px;
				margin-left: 2.78vw;
				margin-right: 2.78vw;
				vertical-align: middle;
			}
		}

		.div-attachment-form {
			display: inline-block;
			min-width: 85vw;

			.label-attachment-form {
				display: inline !important;
				vertical-align: middle;
			}

			.img-remove-attachment-form {
				height: 20px;
				margin-left: 2.78vw;
				vertical-align: middle;
			}
		}

		.img-body-form {
			width: 38.89vw;
			margin-right: 2vw;
			margin-bottom: 20px;
			vertical-align: middle;
		}

		.div-buttons-navigation-form {
			margin: 30px auto;
			width: 100%;
			display: inline-block;
			text-align: center;

			.button-previous-form {
				width: 33.89vw;
				height: 40px;
				border-radius: 4px;
				border: solid 1px var(--greyish-brown);
				background-color: var(--white);
				margin-right: 4.17vw !important;
			}

			.button-next-form {
				width: 33.89vw;
				height: 40px;
				border-radius: 4px;
				background-color: var(--azure);
			}
		}

		.div-title-sign-form {
			display: flex;
			align-items: center;
			margin: 0 0 15px !important;

			.span-1-title-sign-form {
				margin-right: 8.33vw;
			}

			.button-title-sign-form {
				padding: 0 !important;
			}

			.span-2-title-sign-form {
				margin: 0 5.55vw;
			}
		}

		.img-sign-form {
			height: 20px !important;
			max-width: unset !important;
		}

		.div-signatory-form {
			width: 91.11vw;
			padding: 15px;
			position: relative;
			vertical-align: middle;

			.button-div-signatory-form {
				position: absolute;
				top: 10px;
				right: 1vw;
			}

			.button-to-sign-form {
				width: 26vw;
				height: 34px;
				border-radius: 4px;
				box-shadow: 0 2px 4px 0 var(--very-light-pink);
				border: solid 1px var(--greyish-brown);
				background-color: var(--white);
			}
		}
	}

	.popover-select-form {
		--max-height: 70%;
		--width: fit-content !important;
		--max-width: 60vw !important;

		.popover-viewport {

			ion-list {

				padding-top: 0 !important;
				padding-bottom: 0 !important;

				.item {
					font-size: 16px;
					color: var(--black);
					border-bottom: 1px solid var(--very-light-pink) !important;
					--background: var(--white);
					--background-activated: var(--white) !important;
					--background-activated-opacity: 1 !important;
					--highlight-color-focused: var(--white) !important;
					--highlight-color-invalid: var(--white) !important;
					--highlight-color-valid: var(--white) !important;
					--background-focused: var(--white) !important;
					--background-focused-opacity: 0 !important;
					--background-hover: var(--white) !important;
					--background-hover-opacity: 0 !important;
					--inner-border-width: 0 !important;
					--color-focused: var(--black) !important;
					--color-hover: var(--black) !important;
					--indicator-color: transparent !important;
					--ripple-color: transparent !important;
				}

				.item-radio-checked {
					--background: var(--azure) !important;

					ion-label {
						color: var(--white) !important;
					}
				}

				.item::part(native) {
					ion-label {
						white-space: pre-wrap !important;
					}
				}

				.item:last-of-type {
					border-bottom: none !important;
				}

				ion-radio::part(container) {
					opacity: 1 !important;
				}
			}
		}
	}

	// buttons

	.button-top-right {
		position: absolute !important;
		top: 5px !important;
		right: 0 !important;
	}
}
