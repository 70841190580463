// Labels

ion-label {
	display: block !important;
}

// Clickable

.clickable:hover {
	cursor: pointer;
}

// searchbar

.searchbar-clear-icon {
	display: none !important;
}

// Focus

*:focus {
	outline: none !important;
	box-shadow: none !important;
}

// Inputs

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none !important;
	margin: 0 !important;
}

input[type=number] {
	-moz-appearance: textfield !important;
}

input[type=date]::-webkit-inner-spin-button,
input[type=date]::-webkit-outer-spin-button {
	-webkit-appearance: none !important;
	margin: 0 !important;
}

.stemi-desktop {
	.input-no-margin-padding {
		display: flex !important;
		padding: 0 !important;
		caret-color: var(--azure);

		input {
			padding-inline-start: 10px;
			padding-inline-end: 10px;
		}
	}

	.textarea-no-margin-padding-left {
		display: flex !important;
		padding: 0 !important;
		caret-color: var(--azure);

		.textarea-wrapper {
			width: 100% !important;

			textarea {
				padding-inline-start: 10px;
				padding-inline-end: 10px;
				transform: none !important;
			}
		}
	}

	.blank-item-stemi {
		padding: 0 !important;
		width: fit-content !important;
		--min-height: initial !important;
		--inner-padding-start: 0 !important;
		--inner-padding-end: 0 !important;
		--inner-padding-bottom: 0 !important;
		--inner-padding-top: 0 !important;
		--padding-start: 0 !important;
		--padding-end: 0 !important;
		--padding-bottom: 0 !important;
		--padding-top: 0 !important;
		--highlight-color-focused: transparent !important;
		--highlight-color-invalid: transparent !important;
		--highlight-color-valid: transparent !important;
		--ripple-color: transparent !important;
		--background-hover: transparent !important;
		--background-activated: transparent !important;
		--background-focused: transparent !important;
		--border-color: transparent;

		.item-inner {
			border: none !important;
			box-shadow: none !important;

			.input-wrapper {

				.label {
					margin: 0 !important;
				}
			}
		}
	}

	.desktop-custom-input-half-over-width {
		width: 355px !important;
		max-width: 355px !important;
		height: 32px;
		border-radius: 4px;
		border: solid 1px #b0b0b0;
		background-color: var(--white);
		display: flex !important;

		input {
			padding-inline-start: 10px;
			padding-inline-end: 10px;
		}
	}

	.datetime-range {
		height: 34px;
		width: 260px !important;
		border-radius: 4px;
		border: solid 1px #b0b0b0;
		background-color: var(--white);
	}

	.datetime-range:hover {
		cursor: pointer;
	}
}

.owl-dt-container {
	font-size: 14px !important;
	font-family: 'MyRoboto' !important;
	border-radius: 4px !important;

	.owl-dt-weekday {
		font-size: 12px !important;
	}

	.owl-dt-calendar-cell-content {
		font-size: 12px !important;
	}

	.owl-dt-calendar-cell-selected {
		background-color: var(--azure) !important;
	}

	.owl-dt-calendar-cell-in-range {
		background: var(--azure-20) !important;
	}

	.owl-dt-container-range-content {
		font-size: 14px !important;
	}

	.owl-dt-container-info-active {
		color: var(--azure) !important;
	}

	.owl-dt-timer {
		height: auto !important;

		.owl-dt-timer-input {
			font-size: 12px !important;
			color: var(--black) !important;
			background: var(--white) !important;
		}

		.owl-dt-timer-divider:after, .owl-dt-timer-divider:before {
			width: .20em !important;
			height: .20em !important;
		}
	}

	.owl-dt-control-button {
		color: var(--black) !important;
	}
}

.owl-dt-container-disabled, .owl-dt-trigger-disabled {
	opacity: 1 !important;
}

.stemi-mobile {
	.input-no-margin-padding {
		display: flex !important;
		padding: 0 !important;
		caret-color: var(--azure);

		input {
			padding-inline-start: 2.5vw;
			padding-inline-end: 2.5vw;
		}
	}

	.textarea-no-margin-padding-left {
		display: flex !important;
		padding: 0 !important;
		caret-color: var(--azure);

		.textarea-wrapper {
			width: 100% !important;

			textarea {
				padding-inline-start: 2.5vw;
				padding-inline-end: 2.5vw;
				transform: none !important;
			}
		}
	}

	.blank-item-stemi {
		padding: 0 !important;
		width: fit-content !important;
		--min-height: initial !important;
		--inner-padding-start: 0 !important;
		--inner-padding-end: 0 !important;
		--inner-padding-bottom: 0 !important;
		--inner-padding-top: 0 !important;
		--padding-start: 0 !important;
		--padding-end: 0 !important;
		--padding-bottom: 0 !important;
		--padding-top: 0 !important;
		--highlight-color-focused: transparent !important;
		--highlight-color-invalid: transparent !important;
		--highlight-color-valid: transparent !important;
		--ripple-color: transparent !important;
		--background-hover: transparent !important;
		--background-activated: transparent !important;
		--background-focused: transparent !important;
		--border-color: transparent;
		--transition: none;
		z-index: 0;

		.item-inner {
			border: none !important;
			box-shadow: none !important;

			.input-wrapper {

				.label {
					margin: 0 !important;
				}
			}
		}
	}

	.mobile-custom-input-full-width {
		width: 83.33vw !important;
		max-width: 83.33vw !important;
		height: 34px !important;
		border-radius: 4px;
		border: solid 1px #b0b0b0;
		background-color: var(--white);
		display: flex !important;

		input {
			padding-inline-start: 2.5vw;
			padding-inline-end: 2.5vw;
		}
	}
}

.stemi-desktop {

	.input-file {
		display: none;
	}
}

.stemi-mobile {

	.input-file {
		display: none;
	}
}

// Segment

.segment-toolbar {

	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	&::-webkit-scrollbar {
		width: 0;
		height: 0;
		display: none;
	}

	.segment-button-toolbar {
		min-height: 40px !important;
		height: 40px !important;
		max-width: fit-content !important;
		text-transform: none !important;
		--background: transparent !important;
		--background-checked: transparent !important;
		--background-focused: transparent !important;
		--background-hover: transparent !important;
		--background-hover-opacity: transparent !important;
		--indicator-box-shadow: none !important;
		--border-width: 0 !important;
		--color-focused: transparent !important;
		--color-hover: transparent !important;
		--indicator-color: transparent !important;
		--padding-end: 0 !important;
		--padding-start: 0 !important;
	}

	.segment-button-checked {
		border: none;
		background-color: transparent !important;
		--background: transparent !important;
	}
}

.stemi-desktop {
	.segment-toolbar {

		.segment-button-toolbar {
			font-size: 16px !important;
			font-weight: bold !important;
			color: var(--greyish-brown) !important;
			--color-checked: var(--greyish-brown) !important;
		}

		.segment-button-toolbar:hover {
			opacity: 0.6 !important;

			ion-label {
				color: var(--greyish-brown) !important;
			}
		}

		.segment-button-checked {
			font-size: 16px !important;
			font-weight: bold !important;
			color: var(--greyish-brown) !important;
			--color-checked: var(--greyish-brown) !important;
		}

		.segment-button-checked:hover {
			opacity: 0.6 !important;
		}
	}
}

.stemi-mobile {
	.segment-toolbar {

		.segment-button-toolbar {
			font-size: 14px !important;
			font-weight: 600 !important;
			color: var(--greyish-brown) !important;
			--color-checked: var(--greyish-brown) !important;
		}

		.segment-button-checked {
			font-size: 14px !important;
			font-weight: 600 !important;
			color: var(--greyish-brown) !important;
			--color-checked: var(--greyish-brown) !important;
		}
	}
}

.label-segment-button-toolbar {
	height: 40px;
	line-height: 40px;
}

.stemi-desktop {

	.desktop-segment-custom-half-width-form {
		width: 343px !important;
		border-radius: 4px;
	}

	.desktop-segment-custom-half-width-with-wrapper-form {
		width: 313px !important;
		border-radius: 4px;
	}

	.desktop-segment-custom-half-over-width-form {
		width: 355px !important;
		border-radius: 4px;
	}

	.segment-stemi {

		.segment-button-stemi {
			min-height: 32px !important;
			height: 32px !important;
			min-width: 85px !important;
			border: solid 1px #b0b0b0 !important;
			border-radius: 0;
			font-size: 16px !important;
			font-weight: inherit;
			color: var(--greyish-brown) !important;
			text-transform: none !important;
			--background: var(--white) !important;
			--background-checked: transparent !important;
			--background-focused: transparent !important;
			--background-hover: transparent !important;
			--background-hover-opacity: transparent !important;
			--indicator-box-shadow: none !important;
			--color-focused: var(--greyish-brown) !important;
			--color-hover: var(--greyish-brown) !important;
			--color-checked: var(--white) !important;
			--indicator-color: transparent !important;
			--padding-end: 0 !important;
			--padding-start: 0 !important;
			--ripple-color: transparent !important;
		}

		.segment-button-stemi:hover {
			opacity: 0.4 !important;
			--color-checked: var(--white) !important;
		}

		.segment-button-checked {
			border: solid 1px var(--azure) !important;
			font-size: 16px !important;
			color: var(--white) !important;
			background-color: var(--azure) !important;
			--background: var(--azure) !important;
		}

		.segment-button-checked:hover {
			opacity: 0.8 !important;
		}

		.segment-button-left {
			border-radius: 4px 0 0 4px;
		}

		.segment-button-right {
			border-radius: 0 4px 4px 0;
		}
	}
}

.stemi-mobile {

	.mobile-segment-custom-full-width-form {
		width: 83.33vw !important;
		border-radius: 4px;
		overflow-y: hidden;
	}

	.segment-stemi {

		.segment-button-stemi {
			min-height: 38px !important;
			height: 38px !important;
			border: solid 1px #b0b0b0 !important;
			border-radius: 0;
			font-size: 16px !important;
			font-weight: inherit;
			color: var(--greyish-brown) !important;
			text-transform: none !important;
			--background: var(--white) !important;
			--background-checked: transparent !important;
			--background-focused: transparent !important;
			--background-hover: transparent !important;
			--background-hover-opacity: transparent !important;
			--indicator-box-shadow: none !important;
			--border-width: 0 !important;
			--color-focused: var(--greyish-brown) !important;
			--color-hover: var(--greyish-brown) !important;
			--color-checked: var(--white) !important;
			--indicator-color: transparent !important;
			--padding-end: 0 !important;
			--padding-start: 0 !important;
			--ripple-color: transparent !important;
		}

		.segment-button-checked {
			border: solid 1px var(--azure) !important;
			font-size: 16px !important;
			color: var(--white) !important;
			background-color: var(--azure) !important;
			--background: var(--azure) !important;
		}

		.segment-button-left {
			border-radius: 4px 0 0 4px;
		}

		.segment-button-right {
			border-radius: 0 4px 4px 0;
		}
	}
}

// Select

.stemi-desktop {

	.desktop-select-custom-half-width {
		width: 343px !important;
	}

	.desktop-select-custom-half-over-width {
		width: 355px !important;
	}

	.select-custom {
		height: 32px !important;
		-webkit-appearance: none;
		border: 1px solid #b0b0b0 !important;
		border-radius: 4px;
		background-color: var(--white);
		background-image: url(../assets/imgs/down-arrow.svg);
		background-position: right center;
		background-repeat: no-repeat;
		background-position-x: 96%;
		background-size: 3.2%;
		text-indent: 9px;
	}

	.ion-select-custom {
		text-indent: 0 !important;
		--padding-start: 10px;
		--padding-end: 20px;
	}

	.ion-select-custom::part(icon) {
		display: none;
	}
}

.stemi-mobile {

	.mobile-select-custom-full-width {
		width: 83.33vw !important;
	}

	.select-custom {
		height: 34px !important;
		-webkit-appearance: none;
		border: 1px solid #b0b0b0 !important;
		border-radius: 4px;
		background-color: var(--white);
		background-image: url(../assets/imgs/down-arrow.svg);
		background-position: right center;
		background-repeat: no-repeat;
		background-position-x: 96%;
		background-size: 3.2%;
	}

	.ion-select-custom {
		text-indent: 0 !important;
		--padding-start: 2.78vw;
		--padding-end: 5.55vw;
		z-index: 0;
	}

	.ion-select-custom::part(icon) {
		display: none;
	}
}

.stemi-mobile, .plt-ios {

	.select-custom {
		text-indent: 1vw;
	}
}

.stemi-mobile, .plt-android {

	.select-custom {
		text-indent: 2.5vw;
	}
}

// Ion Selectable

.stemi-desktop {

	.desktop-ion-selectable-half-width {
		width: 343px !important;
	}

	.desktop-ion-selectable-half-width-with-wrapper {
		width: 313px !important;
	}

	.desktop-ion-selectable-half-over-width {
		width: 355px !important;
	}

	.desktop-ion-selectable {
		border: 1px solid #b0b0b0 !important;
		border-radius: 4px;
		--min-height: 30px !important;
		--inner-padding-start: 0 !important;
		--inner-padding-end: 0 !important;
		--inner-padding-bottom: 0 !important;
		--inner-padding-top: 0 !important;
		--padding-start: 10px !important;
		--padding-end: 10px !important;
		--padding-bottom: 0 !important;
		--padding-top: 0 !important;
		--highlight-color-focused: transparent !important;
		--highlight-color-invalid: transparent !important;
		--highlight-color-valid: transparent !important;
		--border-color: transparent;

		.label-ion-selectable {
			white-space: pre-line !important;
			padding: 7px 0;
		}

		.ionic-selectable {
			height: 30px;

			.ionic-selectable-value {
				display: none;
			}

			.ionic-selectable-icon {

				.ionic-selectable-icon-inner {
					top: 15px !important;
					color: var(--twilight-blue) !important;
				}
			}
		}

		.ionic-selectable:not(.ionic-selectable-is-enabled) {
			opacity: 1 !important;
		}
	}

	.desktop-ion-selectable.item-disabled {
		opacity: 1 !important;
	}

	.desktop-ion-selectable.item-disabled {
		--background: var(--very-light-pink) !important;
	}

	.ion-selectable-modal-stemi::part(content) {
		box-shadow: none !important;
		width: 389px !important;
		border-radius: 4px !important;
		background-color: var(--white);
	}

	.ion-selectable-modal-stemi {

		ion-header {
			ion-toolbar {
				--background-color: var(--white) !important;
				border: none !important;
				--border-color: transparent !important;

				.img-selectable-modal {
					height: 18px;
					margin: 10px;
				}

				ion-title {
					padding-right: 0;
					padding-top: 20px;
					padding-bottom: 10px;
				}

				ion-searchbar {
					height: 40px !important;
					border-radius: 4px;
					min-height: 40px !important;
					padding: 0;
					margin: auto;
					caret-color: var(--azure) !important;

					.searchbar-input-container {
						border: 1px solid #b0b0b0 !important;
						border-radius: 4px;
						--box-shadow: none;

						.searchbar-search-icon {
							display: none !important;
						}

						.searchbar-input {
							height: 38px !important;
							border-radius: 4px;
							font-size: 16px !important;
							color: var(--black) !important;
							font-weight: normal;
							padding-left: 10px !important;
							background-color: var(--white);
						}
					}
				}
			}

			.toolbar-searchbar {
				padding-left: 20px;
				padding-right: 20px;
			}
		}

		ion-header::after {
			background: none !important;
		}

		ion-content {

			.ionic-selectable-item {
				font-size: 14px !important;
				color: var(--black);
				--border-color: transparent !important;

				ion-icon {
					color: var(--twilight-blue) !important;
					--ion-color-base: var(--twilight-blue) !important;
					vertical-align: middle;
				}

				ion-icon::before {
					vertical-align: middle;
				}

				ion-label {
					border: none !important;
					font-size: 16px !important;
					white-space: normal !important;
				}
			}

			.ionic-selectable-item:hover {
				opacity: 0.6;
			}
		}

		ion-footer {
			text-align: center;

			ion-toolbar {

				padding: 0 !important;
				--border-color: transparent !important;

				ion-col {
					padding: 0 !important;

					ion-button {
						width: 104px !important;
						max-width: 104px !important;
						min-width: 104px !important;
						height: 40px !important;
						font-size: 14px;
						font-weight: bold;
						--color: var(--white);
						--background: var(--azure);
						margin: 20px auto !important;
						text-transform: none;
					}

					ion-button:hover {
						--opacity: 0.8 !important;
					}
				}
			}
		}

		ion-footer::before {
			background: none !important;
		}
	}
}

.stemi-mobile {

	.mobile-ion-selectable-full-width {
		width: 83.33vw !important;
	}

	.mobile-ion-selectable {
		border: 1px solid #b0b0b0 !important;
		border-radius: 4px;
		--min-height: 34px !important;
		--inner-padding-start: 0 !important;
		--inner-padding-end: 0 !important;
		--inner-padding-bottom: 0 !important;
		--inner-padding-top: 0 !important;
		--padding-start: 2.78vw !important;
		--padding-end: 2.78vw !important;
		--padding-bottom: 0 !important;
		--padding-top: 0 !important;
		--highlight-color-focused: transparent !important;
		--highlight-color-invalid: transparent !important;
		--highlight-color-valid: transparent !important;
		--border-color: transparent;

		.label-ion-selectable {
			white-space: pre-line !important;
			padding: 7px 0;
		}

		.ionic-selectable {
			height: 34px;

			.ionic-selectable-value {
				display: none;
			}

			.ionic-selectable-icon {

				.ionic-selectable-icon-inner {
					top: 15px !important;
					color: var(--twilight-blue) !important;
				}
			}
		}

		.ionic-selectable:not(.ionic-selectable-is-enabled) {
			opacity: 1 !important;
		}
	}

	.mobile-ion-selectable.item-disabled {
		opacity: 1 !important;
	}

	.mobile-ion-selectable.item-disabled {
		--background: var(--very-light-pink) !important;
	}

	.ion-selectable-modal-stemi::part(content) {
		border-radius: 4px !important;
		background-color: var(--white);
	}

	.ion-selectable-modal-stemi {

		ion-header {
			ion-toolbar {
				--background-color: var(--white) !important;
				--background: var(--white) !important;
				border: none !important;
				--border-color: transparent;

				.img-selectable-modal {
					height: 16px;
					margin: 10px;
				}

				ion-title {
					padding-left: 5.55vw;
					padding-right: 5.55vw;
					text-align: left;
				}

				ion-searchbar {
					height: 36px !important;
					--border-radius: 4px;
					padding: 0;
					margin: auto;
					caret-color: var(--azure) !important;

					.searchbar-input-container {
						border: 1px solid #b0b0b0 !important;
						border-radius: 4px;
						--box-shadow: none;

						.searchbar-search-icon {
							display: none !important;
						}

						.searchbar-input {
							border-radius: 4px;
							font-size: 16px !important;
							color: var(--black) !important;
							font-weight: normal;
							padding-left: 2.78vw !important;
							background-color: var(--white);
						}
					}
				}
			}

			.toolbar-searchbar {
				padding-left: 5.55vw;
				padding-right: 5.55vw;
			}
		}

		ion-header::after {
			background: none !important;
		}

		ion-content {

			.ionic-selectable-item {
				font-size: 14px !important;
				color: var(--black);
				--border-color: transparent !important;

				ion-icon {
					color: var(--twilight-blue) !important;
					--ion-color-base: var(--twilight-blue) !important;
					vertical-align: middle;
				}

				ion-icon::before {
					vertical-align: middle;
				}

				ion-label {
					border: none !important;
					font-size: 16px !important;
					white-space: normal !important;
				}
			}

			.ionic-selectable-item:hover {
				opacity: 0.6;
			}
		}

		ion-footer {
			text-align: center;

			ion-toolbar {

				padding: 0 !important;
				--border-color: transparent;

				ion-col {
					padding: 0 !important;

					ion-button {
						width: 26.94vw !important;
						max-width: 26.94vw !important;
						min-width: 26.94vw !important;
						height: 40px !important;
						font-size: 13px;
						font-weight: bold;
						--color: var(--white);
						--background: var(--azure);
						margin: 10px auto !important;
						text-transform: none;
					}

					ion-button:hover {
						--opacity: 0.8 !important;
					}
				}
			}
		}

		ion-footer::before {
			background: none !important;
		}
	}
}

// Alerts

.stemi-desktop {

	.custom-alert {

		.alert-wrapper {
			width: 400px !important;
			max-width: 400px !important;
			border-radius: 4px !important;
			background-color: var(--white) !important;

			.alert-head {
				text-align: left;
			}

			.alert-message {
				font-size: 16px !important;
				color: var(--greyish-brown) !important;
				overflow-y: auto !important;
				text-align: left;
			}

			.alert-button-group {

				justify-content: flex-end !important;

				.button-validate-alert {
					width: 84px !important;
					max-width: 84px !important;
					min-width: 84px !important;
					height: 40px !important;
					border-radius: 4px !important;
					background-color: var(--azure) !important;
					border: none !important;
					text-transform: none !important;
					margin: 0 15px 15px 0 !important;

					.alert-button-inner {
						justify-content: center !important;
					}
				}

				.button-validate-alert:hover {
					opacity: 0.8;
				}

				.button-cancel-alert {
					width: 84px !important;
					max-width: 84px !important;
					min-width: 84px !important;
					height: 40px !important;
					background-color: transparent !important;
					border: none !important;
					text-transform: none !important;
					margin: 0 10px 15px 0 !important;

					.alert-button-inner {
						justify-content: center !important;
						text-decoration: underline;
					}
				}

				.button-cancel-alert:hover {
					opacity: 0.6;
				}

				.button-leave-form-alert {
					width: 200px !important;
					max-width: 200px !important;
					min-width: 200px !important;
					height: 40px !important;
					border-radius: 4px !important;
					background-color: var(--azure) !important;
					border: none !important;
					text-transform: none !important;
					margin: 0 15px 15px 0 !important;

					.alert-button-inner {
						justify-content: center !important;
					}
				}

				.button-leave-form-alert:hover {
					opacity: 0.8;
				}
			}

			.alert-input-wrapper {
				height: 40px !important;
				border: none !important;
				margin-bottom: 15px;

				.alert-input {
					height: 40px !important;
					margin: 0 !important;
					padding: 0 0 0 10px !important;
					border: solid 1px #b0b0b0;
					border-radius: 4px;
					caret-color: var(--azure) !important;
				}
			}
		}
	}

	.custom-alert-multiple-choices {

		.alert-wrapper {

			.alert-button-group-vertical {
				flex-direction: row-reverse !important;
			}
		}
	}

	.select-alert {

		.alert-head {
			text-align: left;

			.alert-title {
				font-size: 16px !important;
			}
		}

		.alert-checkbox-group {
			max-height: 90%;

			[aria-checked=true] .alert-checkbox-icon {
				background-color: var(--azure);
				border-color: var(--azure);
			}
		}

		.alert-button {
			text-transform: none !important;
			font-size: 16px !important;
			color: var(--azure);
		}
	}

}

.stemi-mobile {

	.custom-alert {

		.alert-wrapper {
			width: 91.67vw !important;
			max-width: 91.67vw !important;
			border-radius: 4px !important;
			background-color: var(--white) !important;

			.alert-head {
				text-align: left;
			}

			.alert-message {
				font-size: 16px !important;
				color: var(--greyish-brown) !important;
				overflow-y: auto !important;
				text-align: left;
			}

			.alert-button-group {

				justify-content: flex-end !important;

				.button-validate-alert {
					width: 26vw !important;
					max-width: 26vw !important;
					min-width: 26vw !important;
					height: 40px !important;
					border-radius: 4px !important;
					background-color: var(--azure) !important;
					border: none !important;
					text-transform: none !important;
					margin: 0 4.17vw 15px 0 !important;

					.alert-button-inner {
						justify-content: center !important;
					}
				}

				.button-cancel-alert {
					width: 26vw !important;
					max-width: 26vw !important;
					min-width: 26vw !important;
					height: 40px !important;
					background-color: transparent !important;
					border: none !important;
					text-transform: none !important;
					margin: 0 1.39vw 15px 0 !important;

					.alert-button-inner {
						justify-content: center !important;
						text-decoration: underline;
					}
				}

				.button-leave-form-alert {
					width: 60vw !important;
					max-width: 60vw !important;
					min-width: 60vw !important;
					height: 40px !important;
					border-radius: 4px !important;
					background-color: var(--azure) !important;
					border: none !important;
					text-transform: none !important;
					margin: 0 4.17vw 15px 0 !important;

					.alert-button-inner {
						justify-content: center !important;
					}
				}
			}

			.alert-input-wrapper {
				height: 34px !important;
				border: none !important;
				margin-bottom: 15px;

				.alert-input {
					height: 34px !important;
					margin: 0 !important;
					padding: 0 0 0 2.78vw !important;
					border: solid 1px #b0b0b0;
					border-radius: 4px;
					caret-color: var(--azure) !important;
				}
			}
		}
	}

	.custom-alert-with-input {
		.alert-wrapper {
			position: absolute;
			top: 100px;
		}
	}

	.custom-alert-multiple-choices {

		.alert-wrapper {

			.alert-button-group-vertical {
				flex-direction: row-reverse !important;
			}
		}
	}

	.select-alert {

		.alert-head {
			text-align: left;

			.alert-title {
				font-size: 16px !important;
			}
		}

		.alert-checkbox-group {
			max-height: 90%;

			[aria-checked=true] .alert-checkbox-icon {
				background-color: var(--azure);
				border-color: var(--azure);
			}
		}

		.alert-button {
			text-transform: none !important;
			font-size: 16px !important;
			color: var(--azure);
		}
	}
}

.stemi-mobile, .plt-ios {

	.custom-alert {

		.alert-wrapper {

			.alert-button-group {
				align-items: flex-end !important;
			}
		}
	}
}

// Modals

.stemi-desktop {

	.custom-modal::part(content) {
		width: 389px;
		border-radius: 4px !important;
		background-color: var(--white);
	}

	.custom-modal {

		.div-custom-modal {
			width: 100%;
			height: 100%;
			padding: 17px;
			position: relative;
			text-align: left;

			.button-close-custom-modal {
				position: absolute;
				top: 17px;
				right: 17px;

				.img-custom-modal {
					height: 18px;
				}
			}

			.button-close-custom-modal:hover {
				opacity: 0.6;
			}

			.label-title-custom-modal {
				margin: 22px 0 30px 0 !important;
			}

			.embed-custom-modal, .video-custom-modal {
				max-width: 750px !important;
				height: auto !important;
				max-height: 413px !important;
				border-radius: 4px;
				border: 1px solid var(--very-light-pink);
				object-fit: contain !important;
				margin-bottom: 20px;
			}

			.embed-custom-modal:hover {
				opacity: 0.6;
			}

			.pdf-custom-modal {
				display: block;

				.ng2-pdf-viewer-container {
					overflow: hidden !important;

					.canvasWrapper {
						border-radius: 4px;
						border: 1px solid var(--very-light-pink);
						margin-bottom: 20px;
						height: 413px !important;
						max-height: 413px !important;
					}
				}
			}

			.pdf-custom-modal:hover {
				opacity: 0.6;
			}

			.div-buttons-custom-modal {
				width: 100%;
				text-align: center;
				position: absolute;
				bottom: 80px;
				left: 50%;
				transform: translateX(-50%);

				.button-previous-custom-modal {
					margin-right: 15px;
					vertical-align: middle;

					.img-2-custom-modal {
						height: 18px;
					}
				}

				.button-previous-custom-modal:hover {
					opacity: 0.6;
				}

				.button-next-custom-modal {
					margin-left: 15px;
					vertical-align: middle;

					.img-2-custom-modal {
						height: 18px;
					}
				}

				.button-next-custom-modal:hover {
					opacity: 0.6;
				}

				.label-buttons-custom-modal {
					display: inline-block;
					vertical-align: middle;
				}
			}

			.div-actions-custom-modal {
				width: 100%;
				text-align: center;
				position: absolute;
				bottom: 15px;
				left: 50%;
				transform: translateX(-50%);

				.desktop-button-actions-custom-modal {
					width: 174px !important;
					height: 40px !important;
					border-radius: 4px;
					background-color: var(--azure);
				}

				.desktop-button-actions-custom-modal:hover {
					opacity: 0.8;
				}
			}
		}
	}
}

.stemi-mobile {

	.custom-modal::part(content) {
		border-radius: 4px !important;
		background-color: var(--white);
	}

	.custom-modal {

		.div-custom-modal {
			width: 100%;
			//height: 100%;
			padding: 15px 8.33vw;
			position: relative;
			text-align: left;

			.button-close-custom-modal {
				position: absolute;
				top: 15px;
				right: 2.78vw;

				.img-custom-modal {
					height: 16px;
				}
			}

			.label-title-custom-modal {
				margin: 22px 0 16px 0 !important;
			}

			.embed-custom-modal, .video-custom-modal {
				width: 100%;
				height: auto !important;
				max-height: 354px !important;
				border-radius: 4px;
				border: 1px solid var(--very-light-pink);
				object-fit: contain !important;
				margin-bottom: 26px;
			}

			.pdf-custom-modal {
				display: block;

				.ng2-pdf-viewer-container {
					overflow: hidden !important;

					.canvasWrapper {
						border-radius: 4px;
						border: 1px solid var(--very-light-pink);
						margin-bottom: 26px;
					}
				}
			}

			.div-buttons-custom-modal {
				width: 100%;
				text-align: center;
				margin-top: 20px !important;

				.button-previous-custom-modal {
					margin-right: 2.8vw;
					vertical-align: middle;

					.img-2-custom-modal {
						height: 16px;
					}
				}

				.button-next-custom-modal {
					margin-left: 2.8vw;
					vertical-align: middle;

					.img-2-custom-modal {
						height: 16px;
					}
				}

				.label-buttons-custom-modal {
					display: inline-block;
					vertical-align: middle;
				}
			}

			.div-actions-custom-modal {
				width: 100%;
				text-align: center;
				margin-top: 20px !important;

				.mobile-button-actions-medium-custom-modal {
					width: 27.78vw !important;
					height: 40px !important;
					border-radius: 4px;
					background-color: var(--azure);
				}

				.mobile-button-actions-large-custom-modal {
					width: 45.83vw !important;
					height: 40px !important;
					border-radius: 4px;
					background-color: var(--azure);
				}
			}
		}
	}
}

.plt-tablet {

	.custom-modal::part(content) {
		top: 0 !important;
		left: 0 !important;
		height: 100% !important;
		width: 100% !important;
	}
}

// Header

.stemi-desktop {

	.toolbar-header {
		white-space: nowrap;
		--background: var(--twilight-blue) !important;

		.title-header {
			display: inline-flex;
			vertical-align: middle;
		}

		.button-new {
			width: 125px;
			height: 34px;
			border-radius: 4px;
			margin-left: 18px;
			vertical-align: middle;

			.img-new {
				width: 18px;
				height: 18px;
				vertical-align: middle;
				margin-right: 8px;
			}

			.span-new {
				vertical-align: middle;
			}
		}

		.button-new:hover {
			opacity: 0.8;
		}

		.back-button {
			color: var(--white) !important;
			margin-left: 15px !important;
			padding: 0 !important;
		}

		.back-button:hover {
			opacity: 0.8;
		}
	}

	.toolbar-header-empty-stemi {
		height: 0 !important;
		--min-height: 0;
	}

	.toolbar-2-header {
		--min-height: 40px !important;
		height: 40px !important;
		--background: var(--very-light-pink) !important;
	}
}

.stemi-mobile {

	.toolbar-header {
		--background: var(--twilight-blue) !important;

		.title-header {
			text-align: center;
			padding-inline-start: 12vw !important;
			padding-inline-end: 12vw !important;
			left: 0 !important;
			top: 0 !important;
			position: absolute !important;
			width: 100% !important;
			height: 100% !important;
		}

		.img-menu-header {
			width: 24px;
		}

		.back-button {
			color: var(--white) !important;
			margin-left: 4.17vw !important;
			padding: 0 !important;
		}
	}

	.toolbar-header-empty-stemi {
		height: 0 !important;
		--min-height: 0;
	}

	.toolbar-2-header {
		--min-height: 40px !important;
		height: 40px !important;
		--background: var(--very-light-pink) !important;
	}
}

// Content

.stemi-desktop {

	.content-with-actions {
		--padding-top: 90px !important;
	}

	.content-with-toolbar {
		--padding-top: 50px !important;
	}
}

.stemi-mobile {

	.content-with-actions {
		--padding-top: 75px !important;
	}

	.content-with-toolbar {
		--padding-top: 50px !important;
	}
}

// Popover

.user-popover {
	--width: auto;
	--background: var(--azure);
}

.select-popover {
	.radio-checked {
		--color-checked: var(--azure) !important;
	}
}

.stemi-mobile {

	.popover-datetime::part(content) {

		top: unset !important;
		left: 0 !important;
		bottom: 0;
		width: 100vw;
		border-radius: 0;
		padding-bottom: env(safe-area-inset-bottom) !important;
	}
}

// Radio

.stemi-desktop {
	.radio-stemi {
		--color-checked: var(--twilight-blue);
		--border-radius: 2px;
		--inner-border-radius: 2px;
	}
}

.stemi-mobile {
	.radio-stemi {
		--color-checked: var(--twilight-blue);
		--border-radius: 2px;
		--inner-border-radius: 2px;
	}
}

// Actions

.stemi-desktop {
	.div-actions {

		white-space: nowrap;
		position: relative !important;

		.custom-searchbar {
			width: 268px !important;
			height: 32px !important;
			border-radius: 4px;
			min-height: 32px !important;
			padding: 0;
			margin: 31px 22px 16px 30px;
			display: inline-flex;
			vertical-align: top;
			--box-shadow: none !important;

			.searchbar-input-container {
				border: solid 1px #b0b0b0;
				border-radius: 4px;

				.searchbar-search-icon {
					display: none !important;
				}

				.searchbar-input {
					height: 32px !important;
					border-radius: 4px;
					font-style: italic;
					font-size: 14px !important;
					padding-left: 11px !important;
				}
			}
		}

		.button-filter {
			width: 103px !important;
			height: 32px !important;
			border: solid 1px var(--twilight-blue);
			border-radius: 4px;
			margin: 31px 22px 16px 0;

			.img-filter {
				height: 16px;
				vertical-align: middle;
				margin-right: 8px;
			}

			.span-filter {
				vertical-align: middle;
				font-size: 14px;
				color: var(--twilight-blue);
			}
		}

		.button-filter:hover {
			opacity: 0.6;
		}

		.button-action {
			width: auto !important;
			height: 32px !important;
			border-radius: 4px;
			margin: 31px 10px 16px 0;
			padding-left: 10px;
			padding-right: 10px;

			.img-action {
				height: 16px;
				vertical-align: middle;
				margin-right: 8px;
			}

			.img-action-no-span {
				margin: 0 !important;
			}

			.span-action {
				vertical-align: middle;
			}
		}

		.button-action:hover {
			opacity: 0.8;
		}
	}
}

.stemi-mobile {
	.div-actions {

		.custom-searchbar {
			width: 61.39vw !important;
			height: 34px !important;
			border-radius: 4px;
			min-height: 34px !important;
			padding: 0;
			margin: 17px 3.33vw 17px 4.44vw;
			display: inline-flex;
			vertical-align: top;
			--box-shadow: none !important;

			.searchbar-input-container {
				border: solid 1px #b0b0b0;
				border-radius: 4px;

				.searchbar-search-icon {
					display: none !important;
				}

				.searchbar-input {
					height: 34px !important;
					border-radius: 4px;
					font-style: italic;
					font-size: 14px;
					padding-left: 2.5vw !important;
				}
			}
		}

		.button-filter {
			width: 26.39vw !important;
			height: 34px !important;
			border: solid 1px var(--twilight-blue);
			border-radius: 4px;
			margin: 17px 15px 17px 0;

			.img-filter {
				height: 16px;
				vertical-align: middle;
				margin-right: 1.67vw;
			}

			.span-filter {
				vertical-align: middle;
				font-size: 14px;
				color: var(--twilight-blue);
			}
		}
	}
}

// Fab

// Fab

.stemi-mobile {
	.button-fab {
		--background: var(--azure);
		--background-activated: var(--azure);
		--background-activated-opacity: 1;
		--background-focused: var(--azure);
		--background-focused-opacity: 1;
		--background-hover: var(--azure);
		--background-hover-opacity: 1;
		--border-color: var(--azure);
		--ripple-color: var(--azure);
		height: 48px !important;
		width: 48px;
	}

	.button-fab-disabled, .button-fab-action-disabled {
		opacity: .4 !important;
	}

	ion-fab-button[data-desc] {
		position: relative !important;
	}

	ion-fab-button[data-desc]::after {
		position: absolute !important;
		content: attr(data-desc) !important;
		top: 50% !important;
		transform: translateY(-50%) !important;
		right: 50px !important;
		background-color: transparent !important;
		color: var(--black) !important;
		font-size: 14px !important;
		font-weight: 500 !important;
	}

	.button-fab-action {
		width: 40px;
		height: 40px;
		--background: var(--greyish-brown);
		--background-activated: var(--greyish-brown);
		--background-focused: var(--greyish-brown);
		--background-hover: var(--greyish-brown);
		--border-color: var(--greyish-brown);
		--ripple-color: var(--greyish-brown);
	}

	.button-fab-action-disabled {
		opacity: .4 !important;
	}

	.fab-button-close-active {

	}

	.fab-opacified {
		opacity: 0.15 !important;
		pointer-events: none;
	}
}

// Images

.img-pagination {
	height: 28px !important;
	vertical-align: middle;
}

.stemi-desktop {

	.img-add, .img-remove {
		height: 20px !important;
	}
}

.stemi-mobile {

	.img-add, .img-remove {
		height: 20px !important;
	}
}

// Margins, alignments and positions

.no-margin {
	margin: 0 !important;
}

.margin-auto {
	margin: auto !important;
}

.no-horizontal-padding {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

.horizontal-align-center {
	align-items: center !important;
	vertical-align: middle;
}

.horizontal-align-top {
	vertical-align: top !important;
}

.no-padding {
	padding: 0 !important;
}

.vertical-align-center {
	text-align: center !important;
}

.vertical-align-right {
	text-align: right !important;
}

.vertical-align-left {
	text-align: left !important;
}

// Grid list

.stemi-desktop {

	.grid-list {
		border-radius: 4px;
		box-shadow: 0 2px 4px 0 var(--very-light-pink);
		border: solid 1px var(--very-light-pink);
		margin-left: 30px;
		margin-bottom: 15px;
		padding: 0;

		.row-header-list {
			position: sticky !important;
			top: 0;
			left: 0;
			right: 0;
			z-index: 1000;
		}

		.row-header-list:hover {
			opacity: 1 !important;
		}

		.row-list {
			border: solid 1px var(--very-light-pink);
			justify-content: center;
			align-items: center;

			.checkbox-list {
				z-index: 0;
			}

			.label-list {
				margin: 3px 0 !important;
			}
		}

		.row-list:hover {
			opacity: 0.6;
		}
	}
}

.stemi-mobile {
	.div-wrapper-list {
		width: 100vw;
		padding: 0 4.44vw 50px 4.44vw;

		.grid-list-forms {
			padding: 10px 2.78vw;
			border-radius: 4px;
			box-shadow: 0 2px 4px 0 var(--very-light-pink);
			border: solid 1px var(--very-light-pink);
			margin-bottom: 10px;

			.img-1-list-forms {
				vertical-align: middle;
				height: 18px;
				margin: 0 1.67vw 0 0 !important;
			}

			.img-2-list-forms {
				vertical-align: middle;
				height: 18px;
				margin-right: 1.67vw !important;
			}
		}

		.checkbox-list {
			z-index: 0;
		}
	}
}

// Checkboxes

ion-checkbox {
	--border-color: rgba(176, 176, 176, 0.5);
	--border-color-checked: var(--twilight-blue);
	--background: var(--white);
	--background-checked: var(--twilight-blue);
	--checkmark-color: transparent;
	--border-radius: 2px;
	--size: 15px;
}

// Spinner

.loader-stemi {
	--background: transparent !important;
	box-shadow: none !important;

	.loading-wrapper {
		box-shadow: none !important;
	}
}

@keyframes rotate {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.stemi-desktop {
	.spinner-custom {
		animation: rotate 800ms linear infinite;
		background: url(../assets/imgs/loader.svg) no-repeat 50%;
		height: 50px;
		width: 50px;
	}
}

.stemi-mobile {
	.spinner-custom {
		animation: rotate 800ms linear infinite;
		background: url(../assets/imgs/loader.svg) no-repeat 50%;
		height: 7.75vh;
		width: 13.5vw;
	}
}

// Hovers

.stemi-desktop {

	.tag-with-hover-strong:hover {
		opacity: 0.6 !important;
	}
}

// Progress bar

.stemi-desktop {

	.progress-outer {
		background-color: var(--very-light-pink-two) !important;
		padding: 0 !important;
		margin: 0 !important;
		border: none !important;
		border-radius: 1px !important;
		width: 100% !important;

		.progress-inner {
			min-width: 0 !important;
			padding: 0 !important;
			border-radius: 1px !important;
			color: var(--twilight-blue) !important;
			background-color: var(--twilight-blue) !important;
			height: 10px !important;
		}
	}
}

.stemi-mobile {

	.progress-outer {
		background-color: var(--very-light-pink-two) !important;
		padding: 0 !important;
		margin: 0 !important;
		border: none !important;
		border-radius: 1px !important;
		width: 100% !important;

		.progress-inner {
			min-width: 0 !important;
			padding: 0 !important;
			border-radius: 1px !important;
			color: var(--twilight-blue) !important;
			background-color: var(--twilight-blue) !important;
			height: 1.5625vw !important;
		}
	}
}

// Styles

.grid-header {
	position: sticky !important;
	top: 0;
	// left: 0;
	// right: 0;
	z-index: 9999;
}

.first-col-fixed {
	position: sticky !important;
	left: 0;
	z-index: 9998;
}

.underline {
	text-decoration: underline !important;
}

.overflow-x-auto {
	overflow-x: auto !important;
}

.overflow-x-hidden {
	overflow-x: hidden !important;
}

.overflow-x-scroll {
	overflow-x: scroll !important;
}

.overflow-y-hidden {
	overflow-y: hidden !important;
}

.height-100 {
	height: 100%;
}

.width-100 {
	width: 100%;
}

.min-width-100 {
	min-width: 100%;
}

.width-fit-content {
	width: fit-content !important;
}

.min-height-O {
	min-height: 0 !important;
}

.hover-opacity-6:hover {
	opacity: 0.6;
}
